// /* stylelint-disable selector-class-pattern */
// @import "./typeography";
@import "./variables";

// // Tweakpane Theme
:root {
	--tp-blade-value-width: 200px;
	--tp-base-background-color: hsl(230deg 11% 4% / 65%);
	--tp-base-shadow-color: hsl(146deg 99% 70% / 25%);
	--tp-button-background-color: hsl(131deg 31% 16% / 100%);
	--tp-button-background-color-active: hsl(141deg 56% 26% / 80%);
	--tp-button-background-color-focus: hsl(141deg 56% 26% / 90%);
	--tp-button-background-color-hover: hsl(141deg 56% 26% / 100%);
	--tp-button-foreground-color: hsl(104deg 72% 46% / 100%);
	--tp-container-background-color: hsl(230deg 7% 75% / 10%);
	--tp-container-background-color-active: hsl(133deg 56% 20% / 25%);
	--tp-container-background-color-focus: hsl(126deg 68% 43% / 20%);
	--tp-container-background-color-hover: hsl(129deg 100% 69% / 15%);
	--tp-container-foreground-color: hsl(121deg 100% 50% / 100%);
	--tp-groove-foreground-color: hsl(131deg 78% 40% / 10%);
	--tp-input-background-color: hsl(119deg 81% 91% / 10%);
	--tp-input-background-color-active: rgb(146 243 144 / 10%);
	--tp-input-background-color-focus: rgb(169 246 167 / 10%);
	--tp-input-background-color-hover: rgb(191 248 190 / 10%);
	--tp-input-foreground-color: hsl(138deg 100% 60% / 100%);
	--tp-label-foreground-color: hsl(124deg 100% 50% / 70%);
	--tp-monitor-background-color: hsl(230deg 7% 0% / 20%);
	--tp-monitor-foreground-color: hsl(119deg 100% 50% / 70%);
}

.tweakpane-wrapper {
	left: 1%;
	right: initial;
	bottom: 1%;
	position: absolute;
	z-index: $z-tweakpane;

	// width: 100%;

	width: 350px;
	max-height: calc(100vh - $console-height - $nav-height - 48px);
	overflow-y: scroll;
	overflow-x: hidden;

	@media screen and (max-height: $breakpoint-mobile) and (orientation: landscape) {
		// left: 40%;
		// width: 58%;
	}

	// .tp-rotv_c {
	// 	display: flex;
	// 	flex-direction: row;
	// 	flex-wrap: wrap;
	// }

	// .tp-fldv {
	// 	width: 25%;
	// }

	// Pane Title
	.tp-rotv_t {
		font-size: 20px !important;
		font-family: $font-family-digital;
	}

	// Pane Inner Container With Folders
	.tp-brkv {
		display: inline-flex;
		flex-wrap: wrap;

		// flex-direction: column;
		max-height: calc(100vh - $console-height - $nav-height - 85px);
		overflow-y: scroll !important;

		@media screen and (max-height: $breakpoint-mobile) and (orientation: landscape) {
			max-height: 90vh;

			// flex-direction: column;

			// max-height: 40vh;
		}

		// Folder Container
		.tp-fldv {
			display: flex;
			flex-direction: column;
			max-width: calc(20% - 16px);
			width: 100%;
			flex-wrap: wrap;
			align-items: flex-start;
			align-content: flex-start;
			font-size: 101% !important;
			margin: 0 4px;
			padding: 4px;

			// Folder Title
			.tp-fldv_t {
				font-size: 16px;
				text-align: center;
				font-family: $font-family-digital;
			}
			@media screen and (max-width: $breakpoint-tablet) {
				max-width: calc(90% / 3);
			}
			@media screen and (max-width: $breakpoint-mobile) {
				max-width: calc(90% / 2);
			}
		}
	}

	// Main Blade Container
	// .tp-lblv {
	// 	width: 100%;
	// 	flex-direction: column;
	// 	text-align: center;
	// 	margin-bottom: 5px;

	// 	// border: 1px solid rgba(0, 255, 0, 0.3);
	// 	padding: 0 !important;

	// 	// Checkmark Wrapper
	// 	.tp-ckbv {
	// 		// background-color: yellow;
	// 		.tp-ckbv_w {
	// 			margin: auto;
	// 		}
	// 	}
	// }

	// Monitor Text
	.tp-sglv {
		display: inline-block;

		input {
			// font-family: $font-family-digital;
			text-align: center;
		}
	}

	.tp-v-lst {
		width: 100%;
	}

	// Child Right
	.tp-lblv_v {
		width: 100%;
		max-width: 100%;

		// flex-grow: 1 5;
		// max-width: 70%;
		@media screen and (max-width: $breakpoint-mobile) {
			// max-width: 50%;
		}
	}

	.tp-btnv_b {
		padding: 5px;
		line-height: 1;
		height: 100%;
		white-space: pre-line;
	}

	.tp-colswv_b {
		border-width: 1px;
		border-radius: 2px;
		border-color: #0f0;
	}

	.tp-btngridv {
		width: 100%;
	}

	.tp-lblv_v {
		// width: 100px;
		// flex: initial !important;
		min-width: 100px;

		// display: none !important;
	}

	// Button
	.tp-brkv .tp-fldv_c {
		width: 100%;
		overflow-y: hidden !important;
		border-left: none;
	}

	.tp-lblv .tp-v-lst {
		padding: 0 !important;
	}

	// Popup
	.tp-popv {
		position: initial;
	}

	.tp-lblv {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		align-content: center;

		// left child
		.tp-lblv_l {
			min-width: 100px;
		}

		// right child
		.tp-lblv_v {
			width: calc(100% - 40px);
		}
	}

	// FPS Popup
	.tp-grlv_t-a {
		z-index: $z-tweakpane + 1;
	}
}

.maximized .tweakpane-wrapper {
	bottom: 3%;
	width: 92%;

	.tp-brkv {
		max-height: 80vh;
	}
}

// Classes added to elements
.pane-vector3 {
	max-width: 100% !important;

	.tp-fldv_c {
		display: flex;
		justify-content: space-around;
	}

	.tp-lblv {
		width: 30%;
	}

	.tp-fldv_b {
		display: none;
	}

	.tp-fldv {
		max-width: 100% !important;
	}

	.tp-lblv {
		// width: 100%;
		// flex-direction: row;
	}

	.tp-fldv_t {
	}

	.tp-lblv_l {
		// width: 10%;
		text-align: center;
	}

	.tp-lblv_v {
		// width: 25%;
		text-align: center;
	}
}

.tweakpane-row--time {
	flex-direction: row !important;

	.tp-lblv_v {
		width: initial !important;
	}
}

/* Tweakpane Vertical Slider */
.pane-slider-v {
	height: 150px;
	width: 12px;
}

.pane-slider-v .tp-sldtxtv_s {
	transform: rotate(-90deg) translateY(-50%);
	transform-origin: 50% 50%;
}

.pane-slider-v .tp-sldtxtv_t {
	transform-origin: 50% 50%;
	transform: rotate(-90deg);
}
