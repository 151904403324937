@use "sass:map";

// Breakpoints
$breakpoint-watch: 200px;
$breakpoint-mobile: 431px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-hd: 1920px;
$breakpoint-4k: 3840px;
$breakpoint-5k: 5120px;
$breakpoint-8k: 7680px;

// UI Sizes
$logo-height: 64px;
$nav-height: 150px;
$console-height: 250px;
$console-height-mobile: 150px;
$toolbar-height: 64px;
@media screen and (max-width: $breakpoint-tablet) and (orientation: landscape) {
	$console-height: 0;
	$nav-height: 110px;
}

@media screen and (max-width: $breakpoint-mobile) and (orientation: portrait) {
	$console-height: 150px;
	$nav-height: 110px;
}

// Z-Index
$z-0: 0;
$z-1000: 1000;
$z-nav: 1009;
$z-console: 1009;
$z-tweakpane: 1015;
$z-cursors: 1002;
$z-loader: 1007;
$z-loader-grid: 1008;
$z-logo: 1010;
$z-logo-bg: 1009;

// Shawn's Color Pallette
$c-purple: #6a00ff;
$c-terminal-green: #0f0;

// Ian's Color Pallette
$c-sea-green: #00e785;
$c-luigi-green: #00be98;
$c-slate: #2a2d32;
$c-charcoal: #203031;
$c-silver: #bcbcbc;
$c-grey: #868688;
$c-orange: #fba600;
$c-yellow: #dbff00;
$c-white: #fff;
$c-black: #000;
$c-light-grey: #212121;
$c-dark-grey: #232323;
$c-sea-blue: #0d565a;
$c-red: #ff4847;
$c-bright-red: #e33735;
$c-seaweed-green: #203031;

// Assets
$a-noise: url("@assets/images/console-noise.png");

// Margins
$tile-margin: 3px;
$tile-margin-all: 24px;

// NES Color Pallette
$black: #000;
$dark-grey: #555;
$light-grey: #999;
$white: #fff;
$dark-red: #a00;
$light-red: #fee;
$dark-green: #0a0;
$light-green: #efe;
$dark-blue: #00a;
$light-blue: #eef;
$dark-yellow: #a50;
$light-yellow: #ffe;
$dark-purple: #a0a;
$light-purple: #fef;
$dark-cyan: #0aa;
$light-cyan: #eff;

// Synthwave Color Pallette
$synthwave-1: #6cf;
$synthwave-2: #fcf;
$synthwave-3: #ffc;
$synthwave-4: #cff;
$synthwave-5: #ccf;
$synthwave-6: #fcc;
$synthwave-7: #cfc;
$synthwave-8: #fc9;
$synthwave-9: #9cf;
$synthwave-10: #c9f;

// Tropical
$tropical-colors: (
	tropical1: #ffa07a,
	tropical2: #20b2aa,
	tropical3: #5f9ea0,
	tropical4: #8b4513,
	tropical5: #ff6347,
	tropical6: #00ced1,
	tropical7: #1e90ff,
	tropical8: #ffd700,
	tropical9: #ff4500,
	tropical10: #2e8b57,
);

// Miami Vice
$miami-vice: (
	miamivice1: #ff6ad5,
	// Hot Pink
	miamivice2: #c774e8,
	// Light Purple
	miamivice3: #ad8cff,
	// Lavender
	miamivice4: #8795e8,
	// Periwinkle Blue
	miamivice5: #94d0ff,
	// Light Sky Blue
	miamivice6: #4fb4f4,
	// Azure
	miamivice7: #00a6fb,
	// Bright Blue
	miamivice8: #0085a1,
	// Cerulean
	miamivice9: #0b3d91,
	// Yale Blue
	miamivice10: #1c1c1c,
	// Off Black
);
