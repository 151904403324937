@import "typeography";
@import "variables";

// @import "@angular/material/theming";
// @include mat-core();

// /* ======== angular material custom theme ======== */
// $my-custom-primary: mat-palette($c-sea-green);
$my-custom-accent: mat-palette($c-sea-green);

// $my-custom-warn: mat-palette($mat-pink);

// // Light theme
// $my-custom-theme: mat-light-theme($my-custom-primary, $my-custom-accent, $my-custom-warn);

// // Dark theme
// $my-custom-theme: mat-dark-theme($my-custom-primary, $my-custom-accent, $my-custom-warn);

// // Main theme defination
// @include angular-material-theme($my-custom-theme);

// // Alternate Angular Material Theme
// .my-alternate-theme {
//   $my-alternate-primary: mat-palette($mat-deep-orange);
//   $my-alternate-accent: mat-palette($mat-light-blue, 400);
//   $my-alternate-warn: mat-palette($mat-pink);
//   // $my-alternate-info: mat-palette($mat-black);

//   $my-alternate-theme: mat-light-theme($my-alternate-primary, $my-alternate-accent, $my-alternate-warn);

//   @include angular-material-theme($my-alternate-theme);
// }
$color-terminal-green: #0f0;
$color-purple: purple;

// Override Angular Material Theme

// Snackbar Component

.mat-mdc-snack-bar-container {
	--mat-mdc-snack-bar-button-color: currentcolor;
	--mdc-snackbar-container-color: #000;
	--mdc-snackbar-supporting-text-color: white;
	--mdc-snackbar-supporting-text-font: "Digital-7", sans-serif;
	--mdc-snackbar-supporting-text-line-height: 20px;
	--mdc-snackbar-supporting-text-size: 14px;
	--mdc-snackbar-supporting-text-weight: 400;

	// background-image: $a-noise;
	margin-top: 180px !important;

	// color: white;
	// z-index: 2002;

	.mat-mdc-simple-snack-bar {
		// font-family: $font-family-monospace, monospace;
		max-width: 50vw;
		min-width: 0;
		text-align: left;
		justify-content: flex-start;

		// align-items: flex-start;
		padding: 5px;
		border-radius: 4px;

		.mat-simple-snack-bar-content {
			padding-left: 5px;
			text-align: left;
			min-width: 0;

			// width: auto;
			// justify-self: flex-start;
			// align-self: center;
			// align-content: flex-start;
			// overflow: hidden; /* Ensures the content is not revealed until the animation */
			// border-right: 0.15em solid #00ff00; /* The typwriter cursor */
			// white-space: nowrap; /* Keeps the content on a single line */
			// margin: 0 auto; /* Gives that scrolling effect as the typing happens */
			// letter-spacing: 0.15em; /* Adjust as needed */
			// animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
			text-overflow: inherit;
		}
		@media screen and (max-width: $breakpoint-mobile) {
			max-width: 100%;
		}

		&::before {
			width: 58px;
			content: " ";

			// background-color: white;
			background-size: cover;
			background-image: url("assets/images/icon_transparent.png");
			height: 40px;
			margin-right: 10px;
			justify-self: center;
			align-self: center;
			text-align: center;
		}

		.mat-simple-snackbar-action {
			justify-self: flex-end;

			/* stylelint-disable-next-line no-descending-specificity */
			button {
				text-transform: uppercase;
				background-color: rgb(255 255 255 / 5%);
			}
		}
	}

	.mat-mdc-snack-bar-label {
		width: initial;
		flex: 0 0 auto !important;
		max-width: calc(100% - 70px);
		word-break: normal;
		white-space: pre-wrap;
	}

	.mdc-snackbar__surface {
		justify-content: center;
	}
}

.mat-body,
.mat-body-2,
.mat-typography {
	font: 400 14px/20px monospace, "Helvetica Neue", sans-serif;
	letter-spacing: normal;
	font-family: monospace;
}

.mat-h2,
.mat-headline-6,
.mat-typography h2 {
	font-family: "Press Start 2P", monospace;
}

.mat-mdc-card-title {
	font-family: $font-family-digital;
	font-size: 24px;
}

.mat-mdc-card-subtitle {
	font-family: $font-family-digital;
	font-size: 20px;
}

.mat-mdc-dialog-actions {
	justify-content: space-between !important;
}

.mat-mdc-dialog-title {
	text-align: center;
}

.mat-mdc-card-content {
	margin-bottom: 0;
}

// .mat-mdc-tooltip {
// 	background-color: $color-purple;
// 	font-family: $font-family-monospace, monospace;
// }

.mat-mdc-tooltip {
	--mdc-plain-tooltip-container-color: #203031;
	--mdc-plain-tooltip-container-padding: 3px;
	--mdc-plain-tooltip-container-border-radius: 3px;
	--mdc-plain-tooltip-supporting-text-color: #0f0;
	--mdc-plain-tooltip-supporting-text-font: $font-family-digital;
	--mdc-plain-tooltip-supporting-text-size: 16px;
	--mdc-plain-tooltip-supporting-text-weight: 400;
	--mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;

	font-size: 16px;
	font-family: $font-family-digital;
	text-transform: uppercase;
}

.mat-mdc-card-header {
	padding: 6px 6px 0 !important;
	text-align: left;
}

.mat-mdc-paginator {
	background: transparent !important;
	color: white;
	font-family: $font-family-euro;
	font-size: 14px;
	text-transform: uppercase;
}

.mat-mdc-paginator-icon {
	fill: rgb(255 255 255 / 100%);
}

// Helpers
.material-icons.md-18 {
	font-size: 18px;
}

.material-icons.md-24 {
	font-size: 24px !important;
	height: 24px !important;
	width: 24px !important;
}

.material-icons.md-36 {
	font-size: 36px;
}

.material-icons.md-48 {
	font-size: 48px;
}

// Standard HTML Overrides
pre {
	background-color: #333;
	color: $color-terminal-green;
	font-family: $font-family-monospace, monospace;
	border: 0.5px solid #444;
	display: flex;
	min-height: 32px;
	position: relative;
	margin: 16px 24px;
	align-items: center;
	padding: 10px;
	border-radius: 4px;
}

hr {
	border: 0.5px solid $color-terminal-green;
}

.mat-mdc-card {
	padding: 5px;
}

.mat-mdc-form-field-infix {
	// width: 100px;
}

.mdc-menu-surface.mat-mdc-select-panel {
	width: auto !important;
}
