/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable scss/at-rule-no-unknown */

// @import "https://fonts.googleapis.com/css2?family=Noto+Color+Emoji&display=optional";
// @import "https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined&display=block";

// ================================ TYPE ================================
$fonts-path: "/assets/type" !default;

@import "material-icons/iconfont/material-icons.css";
@font-face {
	font-family: "Press Start 2P";
	src: url("#{$fonts-path}/PressStart2P-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Nabla;
	src:
		url("#{$fonts-path}/Nabla-Variable.ttf") format("truetype-variations"),
		url("#{$fonts-path}/Nabla-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: block;
	font-variation-settings:
		"EDPT" 70,
		"EHLT" 12;
}

@font-face {
	font-family: "Digital-7";
	src:
		url("#{$fonts-path}/Digital-7.woff2") format("woff2"),
		url("#{$fonts-path}/Digital-7.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: block;
	text-transform: uppercase;
}

@font-face {
	font-family: "Eurostile LT Pro Unicode";
	src:
		url("#{$fonts-path}/EurostileLTProUnicode.woff2") format("woff2"),
		url("#{$fonts-path}/EurostileLTProUnicode.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

// Font family variables
$font-family-monospace: sfmono-regular, menlo, monaco, consolas, monospace;
$font-family-arcade: "Press Start 2P", monospace;
$font-family-digital: "Digital-7", monospace;
$font-family-euro: "Eurostile LT Pro Unicode", sans-serif;

// Nabla font palette values
@font-palette-values --1 {
	font-family: Nabla;
	base-palette: 1;
}

@font-palette-values --2 {
	font-family: Nabla;
	base-palette: 2;
}

$font-family-nabla: "Nabla", "Press Start2P", sfmono-regular, monospace;

// Typography styles
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-nabla;
	font-weight: 400;
	font-style: normal;
	font-display: block;
	font-palette: --2;
	font-variation-settings:
		"EDPT" 70,
		"EHLT" 12;
}

h3 {
	font-size: 1.8rem;
}

h4 {
	font-size: 1.2rem;
}

h5 {
	font-size: 1rem;
}

pre a {
	color: white;
}
