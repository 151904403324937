/* The Goal is to pull the parts desired over to theme.scss and sunset this file */
.mat-ripple {
	overflow: hidden;
	position: relative;
}

.mat-ripple:not(:empty) {
	transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
	overflow: visible;
}

.mat-ripple-element {
	position: absolute;
	border-radius: 50%;
	pointer-events: none;
	transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
	transform: scale3d(0, 0, 0);
}

.cdk-high-contrast-active .mat-ripple-element {
	display: none;
}

.cdk-visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	white-space: nowrap;
	outline: 0;
	appearance: none;
	left: 0;
}

[dir="rtl"] .cdk-visually-hidden {
	left: auto;
	right: 0;
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
	pointer-events: none;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.cdk-overlay-container {
	position: fixed;
	z-index: 1000;
}

.cdk-overlay-container:empty {
	display: none;
}

.cdk-global-overlay-wrapper {
	display: flex;
	position: absolute;
	z-index: 1000;
}

.cdk-overlay-pane {
	position: absolute;
	pointer-events: auto;
	box-sizing: border-box;
	z-index: 1000;
	display: flex;
	max-width: 100%;
	max-height: 100%;
}

.cdk-overlay-backdrop {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	pointer-events: auto;
	-webkit-tap-highlight-color: rgb(0 0 0 / 0%);
	transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
	opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
	opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
	opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
	background: rgb(0 0 0 / 32%);
}

.cdk-overlay-transparent-backdrop {
	transition: visibility 1ms linear, opacity 1ms linear;
	visibility: hidden;
	opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
	opacity: 0;
	visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
	transition: none;
}

.cdk-overlay-connected-position-bounding-box {
	position: absolute;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	min-width: 1px;
	min-height: 1px;
}

.cdk-global-scrollblock {
	position: fixed;
	width: 100%;
	overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
	resize: none;
}

textarea.cdk-textarea-autosize-measuring {
	padding: 2px 0 !important;
	box-sizing: content-box !important;
	height: auto !important;
	overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
	padding: 2px 0 !important;
	box-sizing: content-box !important;
	height: 0 !important;
}
@keyframes cdk-text-field-autofill-start {
	/* ! */
}
@keyframes cdk-text-field-autofill-end {
	/* ! */
}

.cdk-text-field-autofill-monitored:-webkit-autofill {
	animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
	animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
	position: relative;
}

.mat-focus-indicator::before {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	box-sizing: border-box;
	pointer-events: none;
	display: var(--mat-focus-indicator-display, none);
	border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
	border-radius: var(--mat-focus-indicator-border-radius, 4px);
}

.mat-focus-indicator:focus::before {
	content: "";
}

.cdk-high-contrast-active {
	--mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
	position: relative;
}

.mat-mdc-focus-indicator::before {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	box-sizing: border-box;
	pointer-events: none;
	display: var(--mat-mdc-focus-indicator-display, none);
	border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
	border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}

.mat-mdc-focus-indicator:focus::before {
	content: "";
}

.cdk-high-contrast-active {
	--mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
	background-color: rgb(255 255 255 / 10%);
}

.mat-mdc-option {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple, .mdc-list-item--disabled) {
	background: rgb(255 255 255 / 8%);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
	color: var(--mdc-theme-primary, #7b1fa2);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
	color: var(--mdc-theme-secondary, #69f0ae);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
	color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-optgroup-label {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mat-pseudo-checkbox {
	color: rgb(255 255 255 / 70%);
}

.mat-pseudo-checkbox::after {
	color: #303030;
}

.mat-pseudo-checkbox-disabled {
	color: #686868;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
	background: #7b1fa2;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
	background: #69f0ae;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
	background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
	background: #686868;
}

.mat-app-background {
	background-color: #303030;
	color: #fff;
}

.mat-elevation-z0,
.mat-mdc-elevation-specific.mat-elevation-z0 {
	box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z1,
.mat-mdc-elevation-specific.mat-elevation-z1 {
	box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z2,
.mat-mdc-elevation-specific.mat-elevation-z2 {
	box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z3,
.mat-mdc-elevation-specific.mat-elevation-z3 {
	box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z4,
.mat-mdc-elevation-specific.mat-elevation-z4 {
	box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z5,
.mat-mdc-elevation-specific.mat-elevation-z5 {
	box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 5px 8px 0 rgb(0 0 0 / 14%), 0 1px 14px 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z6,
.mat-mdc-elevation-specific.mat-elevation-z6 {
	box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
}

.mat-elevation-z7,
.mat-mdc-elevation-specific.mat-elevation-z7 {
	box-shadow: 0 4px 5px -2px rgb(0 0 0 / 20%), 0 7px 10px 1px rgb(0 0 0 / 14%), 0 2px 16px 1px rgb(0 0 0 / 12%);
}

.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
	box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
}

.mat-elevation-z9,
.mat-mdc-elevation-specific.mat-elevation-z9 {
	box-shadow: 0 5px 6px -3px rgb(0 0 0 / 20%), 0 9px 12px 1px rgb(0 0 0 / 14%), 0 3px 16px 2px rgb(0 0 0 / 12%);
}

.mat-elevation-z10,
.mat-mdc-elevation-specific.mat-elevation-z10 {
	box-shadow: 0 6px 6px -3px rgb(0 0 0 / 20%), 0 10px 14px 1px rgb(0 0 0 / 14%), 0 4px 18px 3px rgb(0 0 0 / 12%);
}

.mat-elevation-z11,
.mat-mdc-elevation-specific.mat-elevation-z11 {
	box-shadow: 0 6px 7px -4px rgb(0 0 0 / 20%), 0 11px 15px 1px rgb(0 0 0 / 14%), 0 4px 20px 3px rgb(0 0 0 / 12%);
}

.mat-elevation-z12,
.mat-mdc-elevation-specific.mat-elevation-z12 {
	box-shadow: 0 7px 8px -4px rgb(0 0 0 / 20%), 0 12px 17px 2px rgb(0 0 0 / 14%), 0 5px 22px 4px rgb(0 0 0 / 12%);
}

.mat-elevation-z13,
.mat-mdc-elevation-specific.mat-elevation-z13 {
	box-shadow: 0 7px 8px -4px rgb(0 0 0 / 20%), 0 13px 19px 2px rgb(0 0 0 / 14%), 0 5px 24px 4px rgb(0 0 0 / 12%);
}

.mat-elevation-z14,
.mat-mdc-elevation-specific.mat-elevation-z14 {
	box-shadow: 0 7px 9px -4px rgb(0 0 0 / 20%), 0 14px 21px 2px rgb(0 0 0 / 14%), 0 5px 26px 4px rgb(0 0 0 / 12%);
}

.mat-elevation-z15,
.mat-mdc-elevation-specific.mat-elevation-z15 {
	box-shadow: 0 8px 9px -5px rgb(0 0 0 / 20%), 0 15px 22px 2px rgb(0 0 0 / 14%), 0 6px 28px 5px rgb(0 0 0 / 12%);
}

.mat-elevation-z16,
.mat-mdc-elevation-specific.mat-elevation-z16 {
	box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%);
}

.mat-elevation-z17,
.mat-mdc-elevation-specific.mat-elevation-z17 {
	box-shadow: 0 8px 11px -5px rgb(0 0 0 / 20%), 0 17px 26px 2px rgb(0 0 0 / 14%), 0 6px 32px 5px rgb(0 0 0 / 12%);
}

.mat-elevation-z18,
.mat-mdc-elevation-specific.mat-elevation-z18 {
	box-shadow: 0 9px 11px -5px rgb(0 0 0 / 20%), 0 18px 28px 2px rgb(0 0 0 / 14%), 0 7px 34px 6px rgb(0 0 0 / 12%);
}

.mat-elevation-z19,
.mat-mdc-elevation-specific.mat-elevation-z19 {
	box-shadow: 0 9px 12px -6px rgb(0 0 0 / 20%), 0 19px 29px 2px rgb(0 0 0 / 14%), 0 7px 36px 6px rgb(0 0 0 / 12%);
}

.mat-elevation-z20,
.mat-mdc-elevation-specific.mat-elevation-z20 {
	box-shadow: 0 10px 13px -6px rgb(0 0 0 / 20%), 0 20px 31px 3px rgb(0 0 0 / 14%), 0 8px 38px 7px rgb(0 0 0 / 12%);
}

.mat-elevation-z21,
.mat-mdc-elevation-specific.mat-elevation-z21 {
	box-shadow: 0 10px 13px -6px rgb(0 0 0 / 20%), 0 21px 33px 3px rgb(0 0 0 / 14%), 0 8px 40px 7px rgb(0 0 0 / 12%);
}

.mat-elevation-z22,
.mat-mdc-elevation-specific.mat-elevation-z22 {
	box-shadow: 0 10px 14px -6px rgb(0 0 0 / 20%), 0 22px 35px 3px rgb(0 0 0 / 14%), 0 8px 42px 7px rgb(0 0 0 / 12%);
}

.mat-elevation-z23,
.mat-mdc-elevation-specific.mat-elevation-z23 {
	box-shadow: 0 11px 14px -7px rgb(0 0 0 / 20%), 0 23px 36px 3px rgb(0 0 0 / 14%), 0 9px 44px 8px rgb(0 0 0 / 12%);
}

.mat-elevation-z24,
.mat-mdc-elevation-specific.mat-elevation-z24 {
	box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
}

.mat-theme-loaded-marker {
	display: none;
}

.mat-mdc-option {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-body1-font-size, 16px);
	line-height: var(--mdc-typography-body1-line-height, 24px);
	font-weight: var(--mdc-typography-body1-font-weight, 400);
	letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
}

.mat-mdc-card {
	box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);

	--mdc-elevated-card-container-color: #424242;
}

.mat-mdc-card-outlined {
	box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%);

	--mdc-outlined-card-outline-color: #595959;
}

.mat-mdc-card-subtitle {
	color: rgb(255 255 255 / 70%);
}

.mat-mdc-card-title {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-headline6-font-size, 20px);
	line-height: var(--mdc-typography-headline6-line-height, 32px);
	font-weight: var(--mdc-typography-headline6-font-weight, 500);
	letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
	text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
	text-transform: var(--mdc-typography-headline6-text-transform, none);
}

.mat-mdc-card-subtitle {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-subtitle2-font-size, 14px);
	line-height: var(--mdc-typography-subtitle2-line-height, 22px);
	font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
	letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
	text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
	text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

.mat-mdc-progress-bar {
	--mdc-linear-progress-active-indicator-color: #7b1fa2;
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
	background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(123, 31, 162, 0.25)'/%3E%3C/svg%3E");
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
	background-color: rgb(123 31 162 / 25%);
}

.mat-mdc-progress-bar.mat-accent {
	--mdc-linear-progress-active-indicator-color: #69f0ae;
}

.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
	background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(105, 240, 174, 0.25)'/%3E%3C/svg%3E");
}

.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
	background-color: rgb(105 240 174 / 25%);
}

.mat-mdc-progress-bar.mat-warn {
	--mdc-linear-progress-active-indicator-color: #f44336;
}

.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
	background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
}

.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
	background-color: rgb(244 67 54 / 25%);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
	color: rgb(255 255 255 / 60%);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
	color: rgb(255 255 255 / 87%);
}
@media all {
	.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
		color: rgb(255 255 255 / 60%);
	}
}
@media all {
	.mdcfield:not(.mdcfield--disabled) .mdcfield__input:input-placeholder {
		color: rgb(255 255 255 / 60%);
	}
}

.mdc-text-field .mdc-text-field__input {
	caret-color: var(--mdc-theme-primary, #7b1fa2);
}

.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
	color: rgb(0 0 0 / 60%);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
	color: rgb(0 0 0 / 60%);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
	color: rgb(0 0 0 / 54%);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
	color: rgb(0 0 0 / 54%);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
	color: rgb(0 0 0 / 60%);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
	color: rgb(0 0 0 / 60%);
}

.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
	background-color: var(--mdc-ripple-color, rgb(255 255 255 / 87%));
}

.mdc-text-field--filled:hover .mdc-text-field__ripple::before,
.mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
	opacity: var(--mdc-ripple-hover-opacity, 0.08);
}

.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before,
.mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
	opacity: var(--mdc-ripple-focus-opacity, 0.24);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
	background-color: #4a4a4a;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
	border-bottom-color: rgb(255 255 255 / 42%);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
	border-bottom-color: rgb(255 255 255 / 87%);
}

.mdc-text-field--filled .mdc-line-ripple::after {
	border-bottom-color: var(--mdc-theme-primary, #7b1fa2);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
	border-color: rgb(255 255 255 / 38%);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
	border-color: rgb(255 255 255 / 87%);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
	border-color: var(--mdc-theme-primary, #7b1fa2);
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
	background-color: var(--mdc-ripple-color, transparent);
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
	color: rgb(123 31 162 / 87%);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
	border-bottom-color: var(--mdc-theme-error, #f44336);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
	border-bottom-color: var(--mdc-theme-error, #f44336);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
	color: var(--mdc-theme-error, #f44336);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
	color: var(--mdc-theme-error, #f44336);
}

.mdc-text-field--invalid .mdc-text-field__input {
	caret-color: var(--mdc-theme-error, #f44336);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
	color: var(--mdc-theme-error, #f44336);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
	border-bottom-color: var(--mdc-theme-error, #f44336);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
	border-color: var(--mdc-theme-error, #f44336);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
	border-color: var(--mdc-theme-error, #f44336);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
	border-color: var(--mdc-theme-error, #f44336);
}

.mdc-text-field--disabled .mdc-text-field__input {
	color: rgb(255 255 255 / 38%);
}
@media all {
	.mdc-text-field--disabled .mdc-text-field__input::placeholder {
		color: rgb(255 255 255 / 38%);
	}
}
@media all {
	.mdcfield--disabled .mdcfield__input:input-placeholder {
		color: rgb(255 255 255 / 38%);
	}
}

.mdc-text-field--disabled .mdc-floating-label {
	color: rgb(255 255 255 / 38%);
}

.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
	color: rgb(0 0 0 / 38%);
}

.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
	color: rgb(0 0 0 / 38%);
}

.mdc-text-field--disabled .mdc-text-field__icon--leading {
	color: rgb(0 0 0 / 30%);
}

.mdc-text-field--disabled .mdc-text-field__icon--trailing {
	color: rgb(0 0 0 / 30%);
}

.mdc-text-field--disabled .mdc-text-field__affix--prefix {
	color: rgb(0 0 0 / 38%);
}

.mdc-text-field--disabled .mdc-text-field__affix--suffix {
	color: rgb(0 0 0 / 38%);
}

.mdc-text-field--disabled .mdc-line-ripple::before {
	border-bottom-color: rgb(255 255 255 / 6%);
}

.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
	border-color: rgb(255 255 255 / 6%);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
	.mdc-text-field--disabled .mdc-text-field__input::placeholder {
		color: GrayText;
	}
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
	.mdcfield--disabled .mdcfield__input:input-placeholder {
		color: GrayText;
	}
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
	.mdc-text-field--disabled .mdc-floating-label {
		color: GrayText;
	}
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
	.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
		color: GrayText;
	}
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
	.mdc-text-field--disabled .mdc-text-field-character-counter,
	.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
		color: GrayText;
	}
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
	.mdc-text-field--disabled .mdc-text-field__icon--leading {
		color: GrayText;
	}
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
	.mdc-text-field--disabled .mdc-text-field__icon--trailing {
		color: GrayText;
	}
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
	.mdc-text-field--disabled .mdc-text-field__affix--prefix {
		color: GrayText;
	}
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
	.mdc-text-field--disabled .mdc-text-field__affix--suffix {
		color: GrayText;
	}
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
	.mdc-text-field--disabled .mdc-line-ripple::before {
		border-bottom-color: GrayText;
	}
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
	.mdc-text-field--disabled .mdc-notched-outline__leading,
	.mdc-text-field--disabled .mdc-notched-outline__notch,
	.mdc-text-field--disabled .mdc-notched-outline__trailing {
		border-color: GrayText;
	}
}

.mdc-text-field--disabled.mdc-text-field--filled {
	background-color: #464646;
}

.mat-mdc-form-field-error {
	color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-focus-overlay {
	background-color: rgb(255 255 255 / 87%);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
	opacity: 0.08;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
	opacity: 0.24;
}

select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
	color: rgb(0 0 0 / 87%);
}

select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
	color: rgb(0 0 0 / 38%);
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
	color: rgb(255 255 255 / 54%);
}

.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
	color: rgb(123 31 162 / 87%);
}

.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
	color: rgb(105 240 174 / 87%);
}

.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
	color: rgb(244 67 54 / 87%);
}

.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
	color: rgb(255 255 255 / 38%);
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
	caret-color: var(--mdc-theme-secondary, #69f0ae);
}

.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
	border-bottom-color: var(--mdc-theme-secondary, #69f0ae);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
	color: rgb(105 240 174 / 87%);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
	border-bottom-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
	border-bottom-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
	color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-accent
	.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid
	+ .mdc-text-field-helper-line
	.mdc-text-field-helper-text--validation-msg {
	color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
	caret-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
	color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
	border-bottom-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
	border-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
	border-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
	border-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
	border-color: var(--mdc-theme-secondary, #69f0ae);
}

.mat-mdc-form-field.mat-warn .mdc-text-field__input {
	caret-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
	border-bottom-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
	color: rgb(244 67 54 / 87%);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
	border-bottom-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
	border-bottom-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
	color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-warn
	.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid
	+ .mdc-text-field-helper-line
	.mdc-text-field-helper-text--validation-msg {
	color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
	caret-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
	color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
	border-bottom-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
	border-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled, .mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
	border-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
	border-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
	border-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
	border-left: 1px solid rgb(0 0 0 / 0%);
}

[dir="rtl"] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
	border-left: none;
	border-right: 1px solid rgb(0 0 0 / 0%);
}

.mat-mdc-form-field-infix {
	min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
	top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
	--mat-mdc-form-field-label-transform: translateY(-34.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));

	transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
	padding-top: 16px;
	padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
	padding-top: 24px;
	padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined, .mdc-text-field--textarea) .mat-mdc-form-field-infix {
	padding-top: 16px;
	padding-bottom: 16px;
}

.mdc-text-field__input {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-subtitle1-font-size, 16px);
	font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
	letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
	text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
	text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mdc-text-field__affix {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-subtitle1-font-size, 16px);
	font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
	letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
	text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
	text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mdc-text-field--textarea .mdc-text-field__input {
	line-height: 1.5rem;
}

.mdc-floating-label {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-subtitle1-font-size, 16px);
	font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
	letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
	text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
	text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-caption-font-size, 12px);
	line-height: var(--mdc-typography-caption-line-height, 20px);
	font-weight: var(--mdc-typography-caption-font-weight, 400);
	letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
	text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
	text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-body1-font-size, 16px);
	line-height: var(--mdc-typography-body1-line-height, 24px);
	font-weight: var(--mdc-typography-body1-font-weight, 400);
	letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
	text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
	text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
	font-size: calc(16px * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
	font-size: 16px;
}

.mdc-menu-surface {
	box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
	background-color: var(--mdc-theme-surface, #424242);
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item__primary-text {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mdc-list-item__secondary-text {
	color: var(--mdc-theme-text-secondary-on-background, rgb(255 255 255 / 70%));
}

.mdc-list-item__overline-text {
	color: var(--mdc-theme-text-hint-on-background, rgb(255 255 255 / 50%));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
	background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
	color: var(--mdc-theme-text-icon-on-background, rgb(255 255 255 / 50%));
}

.mdc-list-item__end {
	color: var(--mdc-theme-text-hint-on-background, rgb(255 255 255 / 50%));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
	opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled .mdc-list-item__secondary-text {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled .mdc-list-item__overline-text {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
	color: var(--mdc-theme-primary, #7b1fa2);
}

.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
	color: var(--mdc-theme-primary, #7b1fa2);
}

.mdc-deprecated-list-group__subheader {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mdc-list-divider::after {
	border-bottom-color: white;
}

.mdc-list-divider {
	background-color: rgb(255 255 255 / 20%);
}

.mat-mdc-select-value {
	color: rgb(255 255 255 / 87%);
}

.mat-mdc-select-placeholder {
	color: rgb(255 255 255 / 60%);
}

.mat-mdc-select-disabled .mat-mdc-select-value {
	color: rgb(255 255 255 / 38%);
}

.mat-mdc-select-arrow {
	color: rgb(255 255 255 / 54%);
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
	color: rgb(123 31 162 / 87%);
}

.mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
	color: rgb(105 240 174 / 87%);
}

.mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
	color: rgb(244 67 54 / 87%);
}

.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
	color: rgb(244 67 54 / 87%);
}

.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
	color: rgb(255 255 255 / 38%);
}

.mat-mdc-select-panel {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-subtitle1-font-size, 16px);
	font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
	letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
	text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
	text-transform: var(--mdc-typography-subtitle1-text-transform, none);
	line-height: 24px;
}

.mat-mdc-select {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-body1-font-size, 16px);
	line-height: var(--mdc-typography-body1-line-height, 24px);
	font-weight: var(--mdc-typography-body1-font-weight, 400);
	letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
	text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
	text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mdc-menu-surface {
	box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
	background-color: var(--mdc-theme-surface, #424242);
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item__primary-text {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mdc-list-item__secondary-text {
	color: var(--mdc-theme-text-secondary-on-background, rgb(255 255 255 / 70%));
}

.mdc-list-item__overline-text {
	color: var(--mdc-theme-text-hint-on-background, rgb(255 255 255 / 50%));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
	background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
	color: var(--mdc-theme-text-icon-on-background, rgb(255 255 255 / 50%));
}

.mdc-list-item__end {
	color: var(--mdc-theme-text-hint-on-background, rgb(255 255 255 / 50%));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
	opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled .mdc-list-item__secondary-text {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled .mdc-list-item__overline-text {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
	color: var(--mdc-theme-primary, #7b1fa2);
}

.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
	color: var(--mdc-theme-primary, #7b1fa2);
}

.mdc-deprecated-list-group__subheader {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mdc-list-divider::after {
	border-bottom-color: white;
}

.mdc-list-divider {
	background-color: rgb(255 255 255 / 20%);
}

.mat-mdc-autocomplete-panel {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-subtitle1-font-size, 16px);
	font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
	letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
	text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
	text-transform: var(--mdc-typography-subtitle1-text-transform, none);
	line-height: 24px;
}

.mat-mdc-dialog-container {
	--mdc-dialog-container-color: #424242;
	--mdc-dialog-with-divider-divider-color: rgb(255 255 255 / 12%);
	--mdc-dialog-subhead-color: rgb(255 255 255 / 87%);
	--mdc-dialog-supporting-text-color: rgb(255 255 255 / 60%);
}

.mat-mdc-dialog-container {
	--mdc-dialog-subhead-font: roboto, sans-serif;
	--mdc-dialog-subhead-line-height: 32px;
	--mdc-dialog-subhead-size: 20px;
	--mdc-dialog-subhead-weight: 500;
	--mdc-dialog-subhead-tracking: 0.0125em;
	--mdc-dialog-supporting-text-font: roboto, sans-serif;
	--mdc-dialog-supporting-text-line-height: 24px;
	--mdc-dialog-supporting-text-size: 16px;
	--mdc-dialog-supporting-text-weight: 400;
	--mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
	--mdc-chip-elevated-container-color: #595959;
	--mdc-chip-elevated-disabled-container-color: #595959;
	--mdc-chip-label-text-color: #fafafa;
	--mdc-chip-disabled-label-text-color: #fafafa;
	--mdc-chip-with-icon-icon-color: #fafafa;
	--mdc-chip-with-icon-disabled-icon-color: #fafafa;
	--mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
	--mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
	--mdc-chip-with-icon-selected-icon-color: #fafafa;
}

.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
	--mdc-chip-elevated-container-color: #7b1fa2;
	--mdc-chip-elevated-disabled-container-color: #7b1fa2;
	--mdc-chip-label-text-color: white;
	--mdc-chip-disabled-label-text-color: white;
	--mdc-chip-with-icon-icon-color: white;
	--mdc-chip-with-icon-disabled-icon-color: white;
	--mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
	--mdc-chip-with-trailing-icon-trailing-icon-color: white;
	--mdc-chip-with-icon-selected-icon-color: white;
}

.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected,
.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
	--mdc-chip-elevated-container-color: #69f0ae;
	--mdc-chip-elevated-disabled-container-color: #69f0ae;
	--mdc-chip-label-text-color: rgb(0 0 0 / 87%);
	--mdc-chip-disabled-label-text-color: rgb(0 0 0 / 87%);
	--mdc-chip-with-icon-icon-color: rgb(0 0 0 / 87%);
	--mdc-chip-with-icon-disabled-icon-color: rgb(0 0 0 / 87%);
	--mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgb(0 0 0 / 87%);
	--mdc-chip-with-trailing-icon-trailing-icon-color: rgb(0 0 0 / 87%);
	--mdc-chip-with-icon-selected-icon-color: rgb(0 0 0 / 87%);
}

.mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected,
.mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
	--mdc-chip-elevated-container-color: #f44336;
	--mdc-chip-elevated-disabled-container-color: #f44336;
	--mdc-chip-label-text-color: white;
	--mdc-chip-disabled-label-text-color: white;
	--mdc-chip-with-icon-icon-color: white;
	--mdc-chip-with-icon-disabled-icon-color: white;
	--mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
	--mdc-chip-with-trailing-icon-trailing-icon-color: white;
	--mdc-chip-with-icon-selected-icon-color: white;
}

.mat-mdc-chip-focus-overlay {
	background: #fff;
}

.mat-mdc-chip {
	height: 32px;
}

.mat-mdc-standard-chip {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-body2-font-size, 14px);
	line-height: var(--mdc-typography-body2-line-height, 20px);
	font-weight: var(--mdc-typography-body2-font-weight, 400);
	letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
	text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
	text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slide-toggle {
	--mdc-switch-disabled-selected-handle-color: #000;
	--mdc-switch-disabled-unselected-handle-color: #000;
	--mdc-switch-disabled-selected-track-color: #f5f5f5;
	--mdc-switch-disabled-unselected-track-color: #f5f5f5;
	--mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
	--mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
	--mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
	--mdc-switch-unselected-focus-track-color: #616161;
	--mdc-switch-unselected-hover-track-color: #616161;
	--mdc-switch-unselected-pressed-track-color: #616161;
	--mdc-switch-unselected-track-color: #616161;
	--mdc-switch-unselected-focus-handle-color: #fafafa;
	--mdc-switch-unselected-hover-handle-color: #fafafa;
	--mdc-switch-unselected-pressed-handle-color: #fafafa;
	--mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
	--mdc-switch-unselected-handle-color: #9e9e9e;
	--mdc-switch-selected-icon-color: #212121;
	--mdc-switch-disabled-selected-icon-color: #212121;
	--mdc-switch-disabled-unselected-icon-color: #212121;
	--mdc-switch-unselected-icon-color: #212121;
}

.mat-mdc-slide-toggle .mdc-form-field {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mat-mdc-slide-toggle .mdc-switch--disabled + label {
	color: rgb(255 255 255 / 50%);
}

.mat-mdc-slide-toggle.mat-primary {
	--mdc-switch-selected-focus-state-layer-color: #ba68c8;
	--mdc-switch-selected-handle-color: #ba68c8;
	--mdc-switch-selected-hover-state-layer-color: #ba68c8;
	--mdc-switch-selected-pressed-state-layer-color: #ba68c8;
	--mdc-switch-selected-focus-handle-color: #ce93d8;
	--mdc-switch-selected-hover-handle-color: #ce93d8;
	--mdc-switch-selected-pressed-handle-color: #ce93d8;
	--mdc-switch-selected-focus-track-color: #8e24aa;
	--mdc-switch-selected-hover-track-color: #8e24aa;
	--mdc-switch-selected-pressed-track-color: #8e24aa;
	--mdc-switch-selected-track-color: #8e24aa;
}

.mat-mdc-slide-toggle.mat-accent {
	--mdc-switch-selected-focus-state-layer-color: #81c784;
	--mdc-switch-selected-handle-color: #81c784;
	--mdc-switch-selected-hover-state-layer-color: #81c784;
	--mdc-switch-selected-pressed-state-layer-color: #81c784;
	--mdc-switch-selected-focus-handle-color: #a5d6a7;
	--mdc-switch-selected-hover-handle-color: #a5d6a7;
	--mdc-switch-selected-pressed-handle-color: #a5d6a7;
	--mdc-switch-selected-focus-track-color: #43a047;
	--mdc-switch-selected-hover-track-color: #43a047;
	--mdc-switch-selected-pressed-track-color: #43a047;
	--mdc-switch-selected-track-color: #43a047;
}

.mat-mdc-slide-toggle.mat-warn {
	--mdc-switch-selected-focus-state-layer-color: #e57373;
	--mdc-switch-selected-handle-color: #e57373;
	--mdc-switch-selected-hover-state-layer-color: #e57373;
	--mdc-switch-selected-pressed-state-layer-color: #e57373;
	--mdc-switch-selected-focus-handle-color: #ef9a9a;
	--mdc-switch-selected-hover-handle-color: #ef9a9a;
	--mdc-switch-selected-pressed-handle-color: #ef9a9a;
	--mdc-switch-selected-focus-track-color: #e53935;
	--mdc-switch-selected-hover-track-color: #e53935;
	--mdc-switch-selected-pressed-track-color: #e53935;
	--mdc-switch-selected-track-color: #e53935;
}

.mat-mdc-slide-toggle {
	--mdc-switch-state-layer-size: 48px;
}

.mdc-form-field {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-body2-font-size, 14px);
	line-height: var(--mdc-typography-body2-line-height, 20px);
	font-weight: var(--mdc-typography-body2-font-weight, 400);
	letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
	text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
	text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-radio-button {
	--mdc-radio-disabled-selected-icon-color: #fff;
	--mdc-radio-disabled-unselected-icon-color: #fff;
	--mdc-radio-unselected-focus-icon-color: #eee;
	--mdc-radio-unselected-hover-icon-color: #eee;
	--mdc-radio-unselected-icon-color: rgb(255 255 255 / 54%);
	--mdc-radio-unselected-pressed-icon-color: rgb(255 255 255 / 54%);
	--mat-mdc-radio-ripple-color: #fff;
}

.mat-mdc-radio-button .mdc-form-field {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mat-mdc-radio-button .mdc-radio--disabled + label {
	color: rgb(255 255 255 / 50%);
}

.mat-mdc-radio-button.mat-primary {
	--mdc-radio-selected-focus-icon-color: #7b1fa2;
	--mdc-radio-selected-hover-icon-color: #7b1fa2;
	--mdc-radio-selected-icon-color: #7b1fa2;
	--mdc-radio-selected-pressed-icon-color: #7b1fa2;
	--mat-mdc-radio-checked-ripple-color: #7b1fa2;
}

.mat-mdc-radio-button.mat-accent {
	--mdc-radio-selected-focus-icon-color: #69f0ae;
	--mdc-radio-selected-hover-icon-color: #69f0ae;
	--mdc-radio-selected-icon-color: #69f0ae;
	--mdc-radio-selected-pressed-icon-color: #69f0ae;
	--mat-mdc-radio-checked-ripple-color: #69f0ae;
}

.mat-mdc-radio-button.mat-warn {
	--mdc-radio-selected-focus-icon-color: #f44336;
	--mdc-radio-selected-hover-icon-color: #f44336;
	--mdc-radio-selected-icon-color: #f44336;
	--mdc-radio-selected-pressed-icon-color: #f44336;
	--mat-mdc-radio-checked-ripple-color: #f44336;
}

.mat-mdc-radio-button .mdc-radio {
	padding: calc((40px - 20px) / 2);
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
	top: calc(-1 * (40px - 20px) / 2);
	left: calc(-1 * (40px - 20px) / 2);
	width: 40px;
	height: 40px;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
	top: calc((40px - 40px) / 2);
	right: calc((40px - 40px) / 2);
	left: calc((40px - 40px) / 2);
	width: 40px;
	height: 40px;
}

.mdc-form-field {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-body2-font-size, 14px);
	line-height: var(--mdc-typography-body2-line-height, 20px);
	font-weight: var(--mdc-typography-body2-font-weight, 400);
	letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
	text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
	text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
	--mdc-slider-label-container-color: white;
	--mdc-slider-label-label-text-color: black;
	--mdc-slider-disabled-handle-color: #fff;
	--mdc-slider-disabled-active-track-color: #fff;
	--mdc-slider-disabled-inactive-track-color: #fff;
	--mdc-slider-with-tick-marks-disabled-container-color: #fff;
	--mat-mdc-slider-value-indicator-opacity: 0.9;
}

.mat-mdc-slider.mat-primary {
	--mdc-slider-handle-color: #7b1fa2;
	--mdc-slider-focus-handle-color: #7b1fa2;
	--mdc-slider-hover-handle-color: #7b1fa2;
	--mdc-slider-active-track-color: #7b1fa2;
	--mdc-slider-inactive-track-color: #7b1fa2;
	--mdc-slider-with-tick-marks-active-container-color: #fff;
	--mdc-slider-with-tick-marks-inactive-container-color: #7b1fa2;
	--mat-mdc-slider-ripple-color: #7b1fa2;
	--mat-mdc-slider-hover-ripple-color: rgb(123 31 162 / 5%);
	--mat-mdc-slider-focus-ripple-color: rgb(123 31 162 / 20%);
}

.mat-mdc-slider.mat-accent {
	--mdc-slider-handle-color: #69f0ae;
	--mdc-slider-focus-handle-color: #69f0ae;
	--mdc-slider-hover-handle-color: #69f0ae;
	--mdc-slider-active-track-color: #69f0ae;
	--mdc-slider-inactive-track-color: #69f0ae;
	--mdc-slider-with-tick-marks-active-container-color: #000;
	--mdc-slider-with-tick-marks-inactive-container-color: #69f0ae;
	--mat-mdc-slider-ripple-color: #69f0ae;
	--mat-mdc-slider-hover-ripple-color: rgb(105 240 174 / 5%);
	--mat-mdc-slider-focus-ripple-color: rgb(105 240 174 / 20%);
}

.mat-mdc-slider.mat-warn {
	--mdc-slider-handle-color: #f44336;
	--mdc-slider-focus-handle-color: #f44336;
	--mdc-slider-hover-handle-color: #f44336;
	--mdc-slider-active-track-color: #f44336;
	--mdc-slider-inactive-track-color: #f44336;
	--mdc-slider-with-tick-marks-active-container-color: #fff;
	--mdc-slider-with-tick-marks-inactive-container-color: #f44336;
	--mat-mdc-slider-ripple-color: #f44336;
	--mat-mdc-slider-hover-ripple-color: rgb(244 67 54 / 5%);
	--mat-mdc-slider-focus-ripple-color: rgb(244 67 54 / 20%);
}

.mat-mdc-slider {
	--mdc-slider-label-label-text-font: roboto, sans-serif;
	--mdc-slider-label-label-text-font-size: 14px;
	--mdc-slider-label-label-text-line-height: 22px;
	--mdc-slider-label-label-text-tracking: 0.0071428571em;
	--mdc-slider-label-label-text-weight: 500;
}

.mdc-menu-surface {
	box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
	background-color: var(--mdc-theme-surface, #424242);
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item__primary-text {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mdc-list-item__secondary-text {
	color: var(--mdc-theme-text-secondary-on-background, rgb(255 255 255 / 70%));
}

.mdc-list-item__overline-text {
	color: var(--mdc-theme-text-hint-on-background, rgb(255 255 255 / 50%));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
	background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
	color: var(--mdc-theme-text-icon-on-background, rgb(255 255 255 / 50%));
}

.mdc-list-item__end {
	color: var(--mdc-theme-text-hint-on-background, rgb(255 255 255 / 50%));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
	opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled .mdc-list-item__secondary-text {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled .mdc-list-item__overline-text {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
	color: var(--mdc-theme-primary, #7b1fa2);
}

.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
	color: var(--mdc-theme-primary, #7b1fa2);
}

.mdc-deprecated-list-group__subheader {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mdc-list-divider::after {
	border-bottom-color: white;
}

.mdc-list-divider {
	background-color: rgb(255 255 255 / 20%);
}

.mat-mdc-menu-item[disabled],
.mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.mat-mdc-menu-item[disabled] .mat-icon-no-color {
	color: var(--mdc-theme-text-disabled-on-background, rgb(255 255 255 / 50%));
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
	background: rgb(255 255 255 / 8%);
}

.mat-mdc-menu-content {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-subtitle1-font-size, 16px);
	font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
	letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
	text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
	text-transform: var(--mdc-typography-subtitle1-text-transform, none);
	line-height: 24px;
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-body1-font-size, 16px);
	line-height: var(--mdc-typography-body1-line-height, 24px);
	font-weight: var(--mdc-typography-body1-font-weight, 400);
	letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
	text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
	text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-list-item-interactive::before {
	background: #fff;
}

/* .mat-mdc-list-item-interactive:not(:focus, .mdc-list-item--disabled):hover::before {
	opacity: 0.08;
} */

.mat-mdc-list-item-interactive.mdc-list-item--selected::before {
	background: #7b1fa2;
	opacity: 0.08;
}

/* .mat-mdc-list-item-interactive.mdc-list-item--selected:not(:focus, .mdc-list-item--disabled):hover::before {
	opacity: 0.12;
} */

.mat-mdc-list-item-interactive:focus::before {
	opacity: 0.24;
}

.mdc-list-item__primary-text {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mdc-list-item__secondary-text {
	color: var(--mdc-theme-text-secondary-on-background, rgb(255 255 255 / 70%));
}

.mdc-list-item__overline-text {
	color: var(--mdc-theme-text-hint-on-background, rgb(255 255 255 / 50%));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
	background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
	color: var(--mdc-theme-text-icon-on-background, rgb(255 255 255 / 50%));
}

.mdc-list-item__end {
	color: var(--mdc-theme-text-hint-on-background, rgb(255 255 255 / 50%));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
	opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled .mdc-list-item__secondary-text {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled .mdc-list-item__overline-text {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
	color: var(--mdc-theme-on-surface, #fff);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
	color: var(--mdc-theme-primary, #7b1fa2);
}

.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
	color: var(--mdc-theme-primary, #7b1fa2);
}

.mdc-deprecated-list-group__subheader {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mdc-list-divider::after {
	border-bottom-color: white;
}

.mdc-list-divider {
	background-color: rgb(255 255 255 / 20%);
}

.mat-mdc-list-option .mdc-list-item__start,
.mat-mdc-list-option .mdc-list-item__end {
	--mdc-checkbox-selected-checkmark-color: #fff;
	--mdc-checkbox-selected-focus-icon-color: #7b1fa2;
	--mdc-checkbox-selected-hover-icon-color: #7b1fa2;
	--mdc-checkbox-selected-icon-color: #7b1fa2;
	--mdc-checkbox-selected-pressed-icon-color: #7b1fa2;
	--mdc-checkbox-unselected-focus-icon-color: #eee;
	--mdc-checkbox-unselected-hover-icon-color: #eee;
	--mdc-checkbox-disabled-selected-icon-color: rgb(255 255 255 / 38%);
	--mdc-checkbox-disabled-unselected-icon-color: rgb(255 255 255 / 38%);
	--mdc-checkbox-unselected-icon-color: rgb(255 255 255 / 54%);
	--mdc-checkbox-unselected-pressed-icon-color: rgb(255 255 255 / 54%);
}

.mat-mdc-list-option.mat-accent .mdc-list-item__start,
.mat-mdc-list-option.mat-accent .mdc-list-item__end {
	--mdc-checkbox-selected-checkmark-color: #000;
	--mdc-checkbox-selected-focus-icon-color: #69f0ae;
	--mdc-checkbox-selected-hover-icon-color: #69f0ae;
	--mdc-checkbox-selected-icon-color: #69f0ae;
	--mdc-checkbox-selected-pressed-icon-color: #69f0ae;
	--mdc-checkbox-unselected-focus-icon-color: #eee;
	--mdc-checkbox-unselected-hover-icon-color: #eee;
	--mdc-checkbox-disabled-selected-icon-color: rgb(255 255 255 / 38%);
	--mdc-checkbox-disabled-unselected-icon-color: rgb(255 255 255 / 38%);
	--mdc-checkbox-unselected-icon-color: rgb(255 255 255 / 54%);
	--mdc-checkbox-unselected-pressed-icon-color: rgb(255 255 255 / 54%);
}

.mat-mdc-list-option.mat-warn .mdc-list-item__start,
.mat-mdc-list-option.mat-warn .mdc-list-item__end {
	--mdc-checkbox-selected-checkmark-color: #fff;
	--mdc-checkbox-selected-focus-icon-color: #f44336;
	--mdc-checkbox-selected-hover-icon-color: #f44336;
	--mdc-checkbox-selected-icon-color: #f44336;
	--mdc-checkbox-selected-pressed-icon-color: #f44336;
	--mdc-checkbox-unselected-focus-icon-color: #eee;
	--mdc-checkbox-unselected-hover-icon-color: #eee;
	--mdc-checkbox-disabled-selected-icon-color: rgb(255 255 255 / 38%);
	--mdc-checkbox-disabled-unselected-icon-color: rgb(255 255 255 / 38%);
	--mdc-checkbox-unselected-icon-color: rgb(255 255 255 / 54%);
	--mdc-checkbox-unselected-pressed-icon-color: rgb(255 255 255 / 54%);
}

.mat-mdc-list-item.mdc-list-item--with-one-line {
	height: 48px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-one-line {
	height: 56px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-one-line,
.mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-one-line {
	height: 72px;
}

.mat-mdc-list-item.mdc-list-item--with-two-lines {
	height: 64px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines,
.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines,
.mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines,
.mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines,
.mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines,
.mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines,
.mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines,
.mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines {
	height: 72px;
}

.mat-mdc-list-item.mdc-list-item--with-three-lines {
	height: 88px;
}

.mat-mdc-list-option-with-trailing-avatar.mdc-list-item--with-one-line {
	height: 56px;
}

.mat-mdc-list-option-with-trailing-avatar.mdc-list-item--with-two-lines {
	height: 72px;
}

.mdc-list {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-subtitle1-font-size, 16px);
	font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
	letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
	text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
	text-transform: var(--mdc-typography-subtitle1-text-transform, none);
	line-height: 24px;
}

.mdc-list-item__primary-text {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-subtitle1-font-size, 16px);
	line-height: var(--mdc-typography-subtitle1-line-height, 28px);
	font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
	letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
	text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
	text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mdc-list-item__secondary-text {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-body2-font-size, 14px);
	line-height: var(--mdc-typography-body2-line-height, 20px);
	font-weight: var(--mdc-typography-body2-font-weight, 400);
	letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
	text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
	text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-list-item__overline-text {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-overline-font-size, 12px);
	line-height: var(--mdc-typography-overline-line-height, 32px);
	font-weight: var(--mdc-typography-overline-font-weight, 500);
	letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
	text-decoration: var(--mdc-typography-overline-text-decoration, none);
	text-transform: var(--mdc-typography-overline-text-transform, none);
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-caption-font-size, 12px);
	line-height: var(--mdc-typography-caption-line-height, 20px);
	font-weight: var(--mdc-typography-caption-font-weight, 400);
	letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
	text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
	text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mdc-list-group__subheader {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-subtitle1-font-size, 16px);
	line-height: var(--mdc-typography-subtitle1-line-height, 28px);
	font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
	letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
	text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
	text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

.mdc-list-item--with-trailing-meta .mdc-list-item__end {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-caption-font-size, 12px);
	line-height: var(--mdc-typography-caption-line-height, 20px);
	font-weight: var(--mdc-typography-caption-font-weight, 400);
	letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
	text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
	text-transform: var(--mdc-typography-caption-text-transform, none);
}
@media all and (-ms-high-contrast: none) {
	.mat-mdc-list-option .mdc-list-item__start .mdc-checkbox .mdc-checkbox__focus-ring,
	.mat-mdc-list-option .mdc-list-item__end .mdc-checkbox .mdc-checkbox__focus-ring {
		display: none;
	}
}

.mat-mdc-list-item .mdc-list-item__primary-text {
	font: 400 16px/24px Roboto, sans-serif;
	letter-spacing: 0.03125em;
}

.mat-mdc-paginator {
	background: #424242;
	color: rgb(255 255 255 / 87%);
}

.mat-mdc-paginator-icon {
	fill: rgb(255 255 255 / 54%);
}

.mat-mdc-paginator-decrement,
.mat-mdc-paginator-increment {
	border-top: 2px solid rgb(255 255 255 / 54%);
	border-right: 2px solid rgb(255 255 255 / 54%);
}

.mat-mdc-paginator-first,
.mat-mdc-paginator-last {
	border-top: 2px solid rgb(255 255 255 / 54%);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
	border-color: rgb(255 255 255 / 12%);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
	fill: rgb(255 255 255 / 12%);
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
	min-height: 40px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
	top: 20px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
	--mat-mdc-form-field-label-transform: translateY(-26.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));

	transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
	padding-top: 8px;
	padding-bottom: 8px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
	padding-top: 8px;
	padding-bottom: 8px;
}

.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined, .mdc-text-field--textarea) .mat-mdc-form-field-infix {
	padding-top: 8px;
	padding-bottom: 8px;
}

.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
	display: none;
}

.mat-mdc-paginator-container {
	min-height: 56px;
}

.mat-mdc-paginator {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-caption-font-size, 12px);
	line-height: var(--mdc-typography-caption-line-height, 20px);
	font-weight: var(--mdc-typography-caption-font-weight, 400);
	letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
	text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
	text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-paginator .mat-mdc-select-value {
	font-size: 12px;
}

.mat-mdc-tab,
.mat-mdc-tab-link {
	background-color: rgb(0 0 0 / 0%);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
	color: rgb(255 255 255 / 60%);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
	color: #7b1fa2;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
	border-color: #7b1fa2;
}

.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
	background-color: #7b1fa2;
}

.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
	color: #69f0ae;
}

.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
	border-color: #69f0ae;
}

.mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
	background-color: #69f0ae;
}

.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
	color: #f44336;
}

.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
	border-color: #f44336;
}

.mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
	background-color: #f44336;
}

.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header-pagination,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header-pagination {
	background-color: var(--mdc-theme-primary, #7b1fa2);
}

.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab-link .mdc-tab__text-label,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab-link .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab-link .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab-link .mdc-tab__text-label {
	color: var(--mdc-theme-on-primary, #fff);
}

.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-mdc-focus-indicator::before,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-focus-indicator::before,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mat-mdc-focus-indicator::before,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-focus-indicator::before {
	border-color: var(--mdc-theme-on-primary, #fff);
}

.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-ripple-element,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-ripple-element,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header-pagination .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header-pagination .mdc-tab__ripple::before {
	background-color: var(--mdc-theme-on-primary, #fff);
}

.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-primary > .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-primary > .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron {
	border-color: var(--mdc-theme-on-primary, #fff);
}

.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header-pagination,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header-pagination {
	background-color: var(--mdc-theme-secondary, #69f0ae);
}

.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab-link .mdc-tab__text-label,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab-link .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab-link .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab-link .mdc-tab__text-label {
	color: var(--mdc-theme-on-secondary, #000);
}

.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-mdc-focus-indicator::before,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-focus-indicator::before,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mat-mdc-focus-indicator::before,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-focus-indicator::before {
	border-color: var(--mdc-theme-on-secondary, #000);
}

.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-ripple-element,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-ripple-element,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header-pagination .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header-pagination .mdc-tab__ripple::before {
	background-color: var(--mdc-theme-on-secondary, #000);
}

.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-accent > .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-accent > .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron {
	border-color: var(--mdc-theme-on-secondary, #000);
}

.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header-pagination,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header-pagination {
	background-color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab-link .mdc-tab__text-label,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab-link .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab-link .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab-link .mdc-tab__text-label {
	color: var(--mdc-theme-on-error, #fff);
}

.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-mdc-focus-indicator::before,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mdc-tab-indicator__content--underline,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-focus-indicator::before,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mat-mdc-focus-indicator::before,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-focus-indicator::before {
	border-color: var(--mdc-theme-on-error, #fff);
}

.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-ripple-element,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-ripple-element,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header-pagination .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header-pagination .mdc-tab__ripple::before {
	background-color: var(--mdc-theme-on-error, #fff);
}

.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-group.mat-background-warn > .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-link-container .mat-mdc-tab-header-pagination-chevron,
.mat-mdc-tab-nav-bar.mat-background-warn > .mat-mdc-tab-header-pagination .mat-mdc-tab-header-pagination-chevron {
	border-color: var(--mdc-theme-on-error, #fff);
}

.mat-mdc-tab-header-pagination-chevron {
	border-color: var(--mdc-theme-on-surface, #fff);
}

.mat-mdc-tab-header .mdc-tab {
	height: 48px;
}

.mdc-tab {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-button-font-size, 14px);
	line-height: var(--mdc-typography-button-line-height, 36px);
	font-weight: var(--mdc-typography-button-font-weight, 500);
	letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
	text-decoration: var(--mdc-typography-button-text-decoration, none);
	text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-checkbox .mdc-form-field {
	color: var(--mdc-theme-text-primary-on-background, white);
}

.mat-mdc-checkbox .mat-ripple-element {
	background-color: rgb(255 255 255 / 10%);
}

.mat-mdc-checkbox .mdc-checkbox__ripple {
	background: #fff;
}

.mat-mdc-checkbox.mat-primary {
	--mdc-checkbox-selected-checkmark-color: #fff;
	--mdc-checkbox-selected-focus-icon-color: #7b1fa2;
	--mdc-checkbox-selected-hover-icon-color: #7b1fa2;
	--mdc-checkbox-selected-icon-color: #7b1fa2;
	--mdc-checkbox-selected-pressed-icon-color: #7b1fa2;
	--mdc-checkbox-unselected-focus-icon-color: #eee;
	--mdc-checkbox-unselected-hover-icon-color: #eee;
	--mdc-checkbox-disabled-selected-icon-color: rgb(255 255 255 / 38%);
	--mdc-checkbox-disabled-unselected-icon-color: rgb(255 255 255 / 38%);
	--mdc-checkbox-unselected-icon-color: rgb(255 255 255 / 54%);
	--mdc-checkbox-unselected-pressed-icon-color: rgb(255 255 255 / 54%);
}

.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
	background-color: rgb(123 31 162 / 10%);
}

.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
	background: #7b1fa2;
}

.mat-mdc-checkbox.mat-accent {
	--mdc-checkbox-selected-checkmark-color: #000;
	--mdc-checkbox-selected-focus-icon-color: #69f0ae;
	--mdc-checkbox-selected-hover-icon-color: #69f0ae;
	--mdc-checkbox-selected-icon-color: #69f0ae;
	--mdc-checkbox-selected-pressed-icon-color: #69f0ae;
	--mdc-checkbox-unselected-focus-icon-color: #eee;
	--mdc-checkbox-unselected-hover-icon-color: #eee;
	--mdc-checkbox-disabled-selected-icon-color: rgb(255 255 255 / 38%);
	--mdc-checkbox-disabled-unselected-icon-color: rgb(255 255 255 / 38%);
	--mdc-checkbox-unselected-icon-color: rgb(255 255 255 / 54%);
	--mdc-checkbox-unselected-pressed-icon-color: rgb(255 255 255 / 54%);
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
	background-color: rgb(105 240 174 / 10%);
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
	background: #69f0ae;
}

.mat-mdc-checkbox.mat-warn {
	--mdc-checkbox-selected-checkmark-color: #fff;
	--mdc-checkbox-selected-focus-icon-color: #f44336;
	--mdc-checkbox-selected-hover-icon-color: #f44336;
	--mdc-checkbox-selected-icon-color: #f44336;
	--mdc-checkbox-selected-pressed-icon-color: #f44336;
	--mdc-checkbox-unselected-focus-icon-color: #eee;
	--mdc-checkbox-unselected-hover-icon-color: #eee;
	--mdc-checkbox-disabled-selected-icon-color: rgb(255 255 255 / 38%);
	--mdc-checkbox-disabled-unselected-icon-color: rgb(255 255 255 / 38%);
	--mdc-checkbox-unselected-icon-color: rgb(255 255 255 / 54%);
	--mdc-checkbox-unselected-pressed-icon-color: rgb(255 255 255 / 54%);
}

.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
	background-color: rgb(244 67 54 / 10%);
}

.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
	background: #f44336;
}

.mat-mdc-checkbox-disabled label {
	color: rgb(255 255 255 / 50%);
}

.mat-mdc-checkbox .mdc-checkbox {
	padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
	margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
	top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
	left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
	top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
	right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
	left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
	width: var(--mdc-checkbox-touch-target-size, 40px);
	height: var(--mdc-checkbox-touch-target-size, 40px);
}
@media all and (-ms-high-contrast: none) {
	.mdc-checkbox .mdc-checkbox__focus-ring {
		display: none;
	}
}

.mdc-form-field {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-body2-font-size, 14px);
	line-height: var(--mdc-typography-body2-line-height, 20px);
	font-weight: var(--mdc-typography-body2-font-weight, 400);
	letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
	text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
	text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-button.mat-unthemed {
	--mdc-text-button-label-text-color: #fff;
}

.mat-mdc-button.mat-primary {
	--mdc-text-button-label-text-color: #7b1fa2;
}

.mat-mdc-button.mat-accent {
	--mdc-text-button-label-text-color: #69f0ae;
}

.mat-mdc-button.mat-warn {
	--mdc-text-button-label-text-color: #f44336;
}

.mat-mdc-button[disabled][disabled] {
	--mdc-text-button-disabled-label-text-color: rgb(255 255 255 / 50%);
	--mdc-text-button-label-text-color: rgb(255 255 255 / 50%);
}

.mat-mdc-unelevated-button.mat-unthemed {
	--mdc-filled-button-container-color: #424242;
	--mdc-filled-button-label-text-color: #fff;
}

.mat-mdc-unelevated-button.mat-primary {
	--mdc-filled-button-container-color: #7b1fa2;
	--mdc-filled-button-label-text-color: #fff;
}

.mat-mdc-unelevated-button.mat-accent {
	--mdc-filled-button-container-color: #69f0ae;
	--mdc-filled-button-label-text-color: #000;
}

.mat-mdc-unelevated-button.mat-warn {
	--mdc-filled-button-container-color: #f44336;
	--mdc-filled-button-label-text-color: #fff;
}

.mat-mdc-unelevated-button[disabled][disabled] {
	--mdc-filled-button-disabled-container-color: rgb(255 255 255 / 12%);
	--mdc-filled-button-disabled-label-text-color: rgb(255 255 255 / 50%);
	--mdc-filled-button-container-color: rgb(255 255 255 / 12%);
	--mdc-filled-button-label-text-color: rgb(255 255 255 / 50%);
}

.mat-mdc-raised-button.mat-unthemed {
	--mdc-protected-button-container-color: #424242;
	--mdc-protected-button-label-text-color: #fff;
}

.mat-mdc-raised-button.mat-primary {
	--mdc-protected-button-container-color: #7b1fa2;
	--mdc-protected-button-label-text-color: #fff;
}

.mat-mdc-raised-button.mat-accent {
	--mdc-protected-button-container-color: #69f0ae;
	--mdc-protected-button-label-text-color: #000;
}

.mat-mdc-raised-button.mat-warn {
	--mdc-protected-button-container-color: #f44336;
	--mdc-protected-button-label-text-color: #fff;
}

.mat-mdc-raised-button[disabled][disabled] {
	--mdc-protected-button-disabled-container-color: rgb(255 255 255 / 12%);
	--mdc-protected-button-disabled-label-text-color: rgb(255 255 255 / 50%);
	--mdc-protected-button-container-color: rgb(255 255 255 / 12%);
	--mdc-protected-button-label-text-color: rgb(255 255 255 / 50%);
	--mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
	--mdc-outlined-button-outline-color: rgb(255 255 255 / 12%);
}

.mat-mdc-outlined-button.mat-unthemed {
	--mdc-outlined-button-label-text-color: #fff;
}

.mat-mdc-outlined-button.mat-primary {
	--mdc-outlined-button-label-text-color: #7b1fa2;
}

.mat-mdc-outlined-button.mat-accent {
	--mdc-outlined-button-label-text-color: #69f0ae;
}

.mat-mdc-outlined-button.mat-warn {
	--mdc-outlined-button-label-text-color: #f44336;
}

.mat-mdc-outlined-button[disabled][disabled] {
	--mdc-outlined-button-label-text-color: rgb(255 255 255 / 50%);
	--mdc-outlined-button-disabled-label-text-color: rgb(255 255 255 / 50%);
	--mdc-outlined-button-outline-color: rgb(255 255 255 / 12%);
	--mdc-outlined-button-disabled-outline-color: rgb(255 255 255 / 12%);
}

.mat-mdc-button,
.mat-mdc-outlined-button {
	--mat-mdc-button-persistent-ripple-color: #fff;
	--mat-mdc-button-ripple-color: rgb(255 255 255 / 10%);
}

.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
	opacity: 0.08;
}

.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
	opacity: 0.24;
}

.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
	opacity: 0.24;
}

.mat-mdc-button.mat-primary,
.mat-mdc-outlined-button.mat-primary {
	--mat-mdc-button-persistent-ripple-color: #7b1fa2;
	--mat-mdc-button-ripple-color: rgb(123 31 162 / 10%);
}

.mat-mdc-button.mat-accent,
.mat-mdc-outlined-button.mat-accent {
	--mat-mdc-button-persistent-ripple-color: #69f0ae;
	--mat-mdc-button-ripple-color: rgb(105 240 174 / 10%);
}

.mat-mdc-button.mat-warn,
.mat-mdc-outlined-button.mat-warn {
	--mat-mdc-button-persistent-ripple-color: #f44336;
	--mat-mdc-button-ripple-color: rgb(244 67 54 / 10%);
}

.mat-mdc-raised-button,
.mat-mdc-unelevated-button {
	--mat-mdc-button-persistent-ripple-color: #fff;
	--mat-mdc-button-ripple-color: rgb(255 255 255 / 10%);
}

.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
	opacity: 0.08;
}

.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
	opacity: 0.24;
}

.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
	opacity: 0.24;
}

.mat-mdc-raised-button.mat-primary,
.mat-mdc-unelevated-button.mat-primary {
	--mat-mdc-button-persistent-ripple-color: #fff;
	--mat-mdc-button-ripple-color: rgb(255 255 255 / 10%);
}

.mat-mdc-raised-button.mat-accent,
.mat-mdc-unelevated-button.mat-accent {
	--mat-mdc-button-persistent-ripple-color: #000;
	--mat-mdc-button-ripple-color: rgb(0 0 0 / 10%);
}

.mat-mdc-raised-button.mat-warn,
.mat-mdc-unelevated-button.mat-warn {
	--mat-mdc-button-persistent-ripple-color: #fff;
	--mat-mdc-button-ripple-color: rgb(255 255 255 / 10%);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
	height: 36px;
}

.mdc-button {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-button-font-size, 14px);
	line-height: var(--mdc-typography-button-line-height, 36px);
	font-weight: var(--mdc-typography-button-font-weight, 500);
	letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
	text-decoration: var(--mdc-typography-button-text-decoration, none);
	text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
	--mat-mdc-button-persistent-ripple-color: #fff;
	--mat-mdc-button-ripple-color: rgb(255 255 255 / 10%);
}

.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
	opacity: 0.08;
}

.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
	opacity: 0.24;
}

.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
	opacity: 0.24;
}

.mat-mdc-icon-button.mat-primary {
	--mat-mdc-button-persistent-ripple-color: #7b1fa2;
	--mat-mdc-button-ripple-color: rgb(123 31 162 / 10%);
}

.mat-mdc-icon-button.mat-accent {
	--mat-mdc-button-persistent-ripple-color: #69f0ae;
	--mat-mdc-button-ripple-color: rgb(105 240 174 / 10%);
}

.mat-mdc-icon-button.mat-warn {
	--mat-mdc-button-persistent-ripple-color: #f44336;
	--mat-mdc-button-ripple-color: rgb(244 67 54 / 10%);
}

.mat-mdc-icon-button.mat-primary {
	--mdc-icon-button-icon-color: #7b1fa2;
}

.mat-mdc-icon-button.mat-accent {
	--mdc-icon-button-icon-color: #69f0ae;
}

.mat-mdc-icon-button.mat-warn {
	--mdc-icon-button-icon-color: #f44336;
}

.mat-mdc-icon-button[disabled][disabled] {
	--mdc-icon-button-icon-color: rgb(255 255 255 / 50%);
	--mdc-icon-button-disabled-icon-color: rgb(255 255 255 / 50%);
}

.mat-mdc-icon-button.mat-mdc-button-base {
	width: 48px;
	height: 48px;
	padding: 12px;
}

.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
	max-height: 48px;
	max-width: 48px;
}

.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
	width: 40px;
	height: 40px;
	margin-top: 4px;
	margin-bottom: 4px;
	margin-right: 4px;
	margin-left: 4px;
}

.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
	max-height: 40px;
	max-width: 40px;
}

.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
	position: absolute;
	top: 50%;
	height: 48px;
	left: 50%;
	width: 48px;
	transform: translate(-50%, -50%);
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
	--mat-mdc-button-persistent-ripple-color: #fff;
	--mat-mdc-button-ripple-color: rgb(255 255 255 / 10%);
}

.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
	opacity: 0.08;
}

.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
	opacity: 0.24;
}

.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
	opacity: 0.24;
}

.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
	--mat-mdc-button-persistent-ripple-color: #fff;
	--mat-mdc-button-ripple-color: rgb(255 255 255 / 10%);
}

.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
	--mat-mdc-button-persistent-ripple-color: #000;
	--mat-mdc-button-ripple-color: rgb(0 0 0 / 10%);
}

.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
	--mat-mdc-button-persistent-ripple-color: #fff;
	--mat-mdc-button-ripple-color: rgb(255 255 255 / 10%);
}

.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
	--mdc-fab-container-color: #424242;
	--mdc-fab-icon-color: #fff;
	--mat-mdc-fab-color: #fff;
}

.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
	--mdc-fab-container-color: #7b1fa2;
	--mdc-fab-icon-color: #fff;
	--mat-mdc-fab-color: #fff;
}

.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
	--mdc-fab-container-color: #69f0ae;
	--mdc-fab-icon-color: #000;
	--mat-mdc-fab-color: #000;
}

.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
	--mdc-fab-container-color: #f44336;
	--mdc-fab-icon-color: #fff;
	--mat-mdc-fab-color: #fff;
}

.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
	--mdc-fab-container-color: rgb(255 255 255 / 12%);
	--mdc-fab-icon-color: rgb(255 255 255 / 50%);
	--mat-mdc-fab-color: rgb(255 255 255 / 50%);
}

.mdc-fab--extended {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-button-font-size, 14px);
	line-height: var(--mdc-typography-button-line-height, 36px);
	font-weight: var(--mdc-typography-button-font-weight, 500);
	letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
	text-decoration: var(--mdc-typography-button-text-decoration, none);
	text-transform: var(--mdc-typography-button-text-transform, none);
}

.mdc-data-table {
	background-color: var(--mdc-theme-surface, #424242);
	border-color: rgb(255 255 255 / 12%);
}

.mdc-data-table__row {
	background-color: inherit;
}

.mdc-data-table__header-cell {
	background-color: var(--mdc-theme-surface, #424242);
}

.mdc-data-table__row--selected {
	background-color: rgb(123 31 162 / 4%);
}

.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
	border-color: rgb(255 255 255 / 12%);
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
	border-bottom-color: rgb(255 255 255 / 12%);
}

.mdc-data-table__pagination {
	border-top-color: rgb(255 255 255 / 12%);
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
	background-color: rgb(255 255 255 / 4%);
}

.mdc-data-table__header-cell {
	color: rgb(255 255 255 / 87%);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
	color: rgb(255 255 255 / 87%);
}

.mat-mdc-table {
	background: #424242;
}

.mat-mdc-table .mdc-data-table__row {
	height: 52px;
}

.mat-mdc-table .mdc-data-table__pagination {
	min-height: 52px;
}

.mat-mdc-table .mdc-data-table__header-row {
	height: 56px;
}

.mdc-data-table__content {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-body2-font-size, 14px);
	line-height: var(--mdc-typography-body2-line-height, 20px);
	font-weight: var(--mdc-typography-body2-font-weight, 400);
	letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
	text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
	text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__cell {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-body2-font-size, 14px);
	line-height: var(--mdc-typography-body2-line-height, 20px);
	font-weight: var(--mdc-typography-body2-font-weight, 400);
	letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
	text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
	text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
	font-size: var(--mdc-typography-subtitle2-font-size, 14px);
	line-height: var(--mdc-typography-subtitle2-line-height, 22px);
	font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
	letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
	text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
	text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

.mat-mdc-progress-spinner {
	--mdc-circular-progress-active-indicator-color: #7b1fa2;
}

.mat-mdc-progress-spinner.mat-accent {
	--mdc-circular-progress-active-indicator-color: #69f0ae;
}

.mat-mdc-progress-spinner.mat-warn {
	--mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
	position: relative;
}

.mat-badge.mat-badge {
	overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
	display: none;
}

.mat-badge-content {
	position: absolute;
	text-align: center;
	display: inline-block;
	border-radius: 50%;
	transition: transform 200ms ease-in-out;
	transform: scale(0.6);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
	transition: none;
}

.mat-badge-content.mat-badge-active {
	transform: none;
}

.mat-badge-small .mat-badge-content {
	width: 16px;
	height: 16px;
	line-height: 16px;
}

.mat-badge-small.mat-badge-above .mat-badge-content {
	top: -8px;
}

.mat-badge-small.mat-badge-below .mat-badge-content {
	bottom: -8px;
}

.mat-badge-small.mat-badge-before .mat-badge-content {
	left: -16px;
}

[dir="rtl"] .mat-badge-small.mat-badge-before .mat-badge-content {
	left: auto;
	right: -16px;
}

.mat-badge-small.mat-badge-after .mat-badge-content {
	right: -16px;
}

[dir="rtl"] .mat-badge-small.mat-badge-after .mat-badge-content {
	right: auto;
	left: -16px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: -8px;
}

[dir="rtl"] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: auto;
	right: -8px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: -8px;
}

[dir="rtl"] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: auto;
	left: -8px;
}

.mat-badge-medium .mat-badge-content {
	width: 22px;
	height: 22px;
	line-height: 22px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
	top: -11px;
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
	bottom: -11px;
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
	left: -22px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-before .mat-badge-content {
	left: auto;
	right: -22px;
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
	right: -22px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-after .mat-badge-content {
	right: auto;
	left: -22px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: -11px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: auto;
	right: -11px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: -11px;
}

[dir="rtl"] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: auto;
	left: -11px;
}

.mat-badge-large .mat-badge-content {
	width: 28px;
	height: 28px;
	line-height: 28px;
}

.mat-badge-large.mat-badge-above .mat-badge-content {
	top: -14px;
}

.mat-badge-large.mat-badge-below .mat-badge-content {
	bottom: -14px;
}

.mat-badge-large.mat-badge-before .mat-badge-content {
	left: -28px;
}

[dir="rtl"] .mat-badge-large.mat-badge-before .mat-badge-content {
	left: auto;
	right: -28px;
}

.mat-badge-large.mat-badge-after .mat-badge-content {
	right: -28px;
}

[dir="rtl"] .mat-badge-large.mat-badge-after .mat-badge-content {
	right: auto;
	left: -28px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: -14px;
}

[dir="rtl"] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
	left: auto;
	right: -14px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: -14px;
}

[dir="rtl"] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
	right: auto;
	left: -14px;
}

.mat-badge-content {
	color: #fff;
	background: #7b1fa2;
}

.cdk-high-contrast-active .mat-badge-content {
	outline: solid 1px;
	border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
	background: #69f0ae;
	color: rgb(0 0 0 / 87%);
}

.mat-badge-warn .mat-badge-content {
	color: #fff;
	background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
	background: #6e6e6e;
	color: rgb(255 255 255 / 50%);
}

.mat-badge-content {
	font-weight: 600;
	font-size: 12px;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
	font-size: 9px;
}

.mat-badge-large .mat-badge-content {
	font-size: 24px;
}

.mat-bottom-sheet-container {
	box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%);
	background: #424242;
	color: #fff;
}

.mat-bottom-sheet-container {
	font: 400 14px/20px Roboto, sans-serif;
	letter-spacing: 0.0178571429em;
}

.mat-button-toggle-standalone:not([class*="mat-elevation-z"]),
.mat-button-toggle-group:not([class*="mat-elevation-z"]) {
	box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*="mat-elevation-z"]),
.mat-button-toggle-group-appearance-standard:not([class*="mat-elevation-z"]) {
	box-shadow: none;
}

.mat-button-toggle {
	color: rgb(255 255 255 / 50%);
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
	background-color: rgb(255 255 255 / 12%);
}

.mat-button-toggle-appearance-standard {
	color: #fff;
	background: #424242;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
	background-color: #fff;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
	border-left: solid 1px #595959;
}

[dir="rtl"] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
	border-left: none;
	border-right: solid 1px #595959;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
	border-left: none;
	border-right: none;
	border-top: solid 1px #595959;
}

.mat-button-toggle-checked {
	background-color: #212121;
	color: rgb(255 255 255 / 70%);
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
	color: #fff;
}

.mat-button-toggle-disabled {
	color: rgb(255 255 255 / 30%);
	background-color: #000;
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
	background: #424242;
}

.mat-button-toggle-disabled.mat-button-toggle-checked {
	background-color: #424242;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
	border: solid 1px #595959;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
	line-height: 48px;
}

.mat-button-toggle {
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-arrow {
	fill: #fff;
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
	color: #fff;
}

.mat-calendar-table-header-divider::after {
	background: rgb(255 255 255 / 12%);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
	color: rgb(255 255 255 / 70%);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
	color: #fff;
	border-color: rgb(0 0 0 / 0%);
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
	color: rgb(255 255 255 / 50%);
}

.mat-form-field-disabled .mat-date-range-input-separator {
	color: rgb(255 255 255 / 50%);
}

.mat-calendar-body-in-preview {
	color: rgb(255 255 255 / 24%);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
	border-color: rgb(255 255 255 / 50%);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
	border-color: rgb(255 255 255 / 30%);
}

.mat-calendar-body-in-range::before {
	background: rgb(123 31 162 / 20%);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
	background: rgb(249 171 0 / 20%);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir="rtl"] .mat-calendar-body-comparison-bridge-end::before {
	background: linear-gradient(to right, rgb(123 31 162 / 20%) 50%, rgb(249 171 0 / 20%) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir="rtl"] .mat-calendar-body-comparison-bridge-start::before {
	background: linear-gradient(to left, rgb(123 31 162 / 20%) 50%, rgb(249 171 0 / 20%) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
	background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
	background: #46a35e;
}

.mat-calendar-body-selected {
	background-color: #7b1fa2;
	color: #fff;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
	background-color: rgb(123 31 162 / 40%);
}

.mat-calendar-body-today.mat-calendar-body-selected {
	box-shadow: inset 0 0 0 1px #fff;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
	background-color: rgb(123 31 162 / 30%);
}
@media (hover: hover) {
	.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
		background-color: rgb(123 31 162 / 30%);
	}
}

.mat-datepicker-content {
	box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
	background-color: #424242;
	color: #fff;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
	background: rgb(105 240 174 / 20%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
	background: rgb(249 171 0 / 20%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir="rtl"] .mat-calendar-body-comparison-bridge-end::before {
	background: linear-gradient(to right, rgb(105 240 174 / 20%) 50%, rgb(249 171 0 / 20%) 50%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir="rtl"] .mat-calendar-body-comparison-bridge-start::before {
	background: linear-gradient(to left, rgb(105 240 174 / 20%) 50%, rgb(249 171 0 / 20%) 50%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
	background: #a8dab5;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
	background: #46a35e;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
	background-color: #69f0ae;
	color: rgb(0 0 0 / 87%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
	background-color: rgb(105 240 174 / 40%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
	box-shadow: inset 0 0 0 1px rgb(0 0 0 / 87%);
}

.mat-datepicker-content.mat-accent
	.cdk-keyboard-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent
	.cdk-program-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
	background-color: rgb(105 240 174 / 30%);
}
@media (hover: hover) {
	.mat-datepicker-content.mat-accent
		.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
		> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
		background-color: rgb(105 240 174 / 30%);
	}
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
	background: rgb(244 67 54 / 20%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
	background: rgb(249 171 0 / 20%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir="rtl"] .mat-calendar-body-comparison-bridge-end::before {
	background: linear-gradient(to right, rgb(244 67 54 / 20%) 50%, rgb(249 171 0 / 20%) 50%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir="rtl"] .mat-calendar-body-comparison-bridge-start::before {
	background: linear-gradient(to left, rgb(244 67 54 / 20%) 50%, rgb(249 171 0 / 20%) 50%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
	background: #a8dab5;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
	background: #46a35e;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
	background-color: #f44336;
	color: #fff;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
	background-color: rgb(244 67 54 / 40%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
	box-shadow: inset 0 0 0 1px #fff;
}

.mat-datepicker-content.mat-warn
	.cdk-keyboard-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn
	.cdk-program-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
	background-color: rgb(244 67 54 / 30%);
}
@media (hover: hover) {
	.mat-datepicker-content.mat-warn
		.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
		> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected, .mat-calendar-body-comparison-identical) {
		background-color: rgb(244 67 54 / 30%);
	}
}

.mat-datepicker-content-touch {
	box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
}

.mat-datepicker-toggle-active {
	color: #7b1fa2;
}

.mat-datepicker-toggle-active.mat-accent {
	color: #69f0ae;
}

.mat-datepicker-toggle-active.mat-warn {
	color: #f44336;
}

.mat-date-range-input-inner[disabled] {
	color: rgb(255 255 255 / 50%);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
	width: 40px;
	height: 40px;
	padding: 8px;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
	max-height: 40px;
	max-width: 40px;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
	width: 40px;
	height: 40px;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
	margin-left: 0;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
	max-height: 40px;
	max-width: 40px;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
	position: absolute;
	top: 50%;
	height: 40px;
	left: 50%;
	width: 40px;
	transform: translate(-50%, -50%);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
	display: none;
}

.mat-calendar {
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
	font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
	font-size: 14px;
	font-weight: 500;
}

.mat-calendar-table-header th {
	font-size: 11px;
	font-weight: 400;
}

.mat-divider {
	border-top-color: rgb(255 255 255 / 12%);
}

.mat-divider-vertical {
	border-right-color: rgb(255 255 255 / 12%);
}

.mat-expansion-panel {
	background: #424242;
	color: #fff;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
	box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.mat-action-row {
	border-top-color: rgb(255 255 255 / 12%);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
	background: rgb(255 255 255 / 4%);
}
@media (hover: none) {
	.mat-expansion-panel:not(.mat-expanded, [aria-disabled="true"]) .mat-expansion-panel-header:hover {
		background: #424242;
	}
}

.mat-expansion-panel-header-title {
	color: #fff;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
	color: rgb(255 255 255 / 70%);
}

.mat-expansion-panel-header[aria-disabled="true"] {
	color: rgb(255 255 255 / 30%);
}

.mat-expansion-panel-header[aria-disabled="true"] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled="true"] .mat-expansion-panel-header-description {
	color: inherit;
}

.mat-expansion-panel-header {
	height: 48px;
}

.mat-expansion-panel-header.mat-expanded {
	height: 64px;
}

.mat-expansion-panel-header {
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 500;
}

.mat-expansion-panel-content {
	font: 400 14px/20px Roboto, sans-serif;
	letter-spacing: 0.0178571429em;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
	font-size: 14px;
}

.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	box-sizing: border-box;
}

.mat-grid-tile-header .mat-line:nth-child(n + 2),
.mat-grid-tile-footer .mat-line:nth-child(n + 2) {
	font-size: 12px;
}

.mat-icon.mat-primary {
	color: #7b1fa2;
}

.mat-icon.mat-accent {
	color: #69f0ae;
}

.mat-icon.mat-warn {
	color: #f44336;
}

.mat-drawer-container {
	background-color: #303030;
	color: #fff;
}

.mat-drawer {
	background-color: #424242;
	color: #fff;
}

.mat-drawer.mat-drawer-push {
	background-color: #424242;
}

.mat-drawer:not(.mat-drawer-side) {
	box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%);
}

.mat-drawer-side {
	border-right: solid 1px rgb(255 255 255 / 12%);
}

.mat-drawer-side.mat-drawer-end {
	border-left: solid 1px rgb(255 255 255 / 12%);
	border-right: none;
}

[dir="rtl"] .mat-drawer-side {
	border-left: solid 1px rgb(255 255 255 / 12%);
	border-right: none;
}

[dir="rtl"] .mat-drawer-side.mat-drawer-end {
	border-left: none;
	border-right: solid 1px rgb(255 255 255 / 12%);
}

.mat-drawer-backdrop.mat-drawer-shown {
	background-color: rgb(189 189 189 / 60%);
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover:not([aria-disabled]),
.mat-step-header:hover[aria-disabled="false"] {
	background-color: rgb(255 255 255 / 4%);
}

.mat-step-header:hover[aria-disabled="true"] {
	cursor: default;
}
@media (hover: none) {
	.mat-step-header:hover {
		background: none;
	}
}

.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
	color: rgb(255 255 255 / 70%);
}

.mat-step-header .mat-step-icon {
	background-color: rgb(255 255 255 / 70%);
	color: #fff;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
	background-color: #7b1fa2;
	color: #fff;
}

.mat-step-header.mat-accent .mat-step-icon {
	color: rgb(0 0 0 / 87%);
}

.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
	background-color: #69f0ae;
	color: rgb(0 0 0 / 87%);
}

.mat-step-header.mat-warn .mat-step-icon {
	color: #fff;
}

.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
	background-color: #f44336;
	color: #fff;
}

.mat-step-header .mat-step-icon-state-error {
	background-color: rgb(0 0 0 / 0%);
	color: #f44336;
}

.mat-step-header .mat-step-label.mat-step-label-active {
	color: #fff;
}

.mat-step-header .mat-step-label.mat-step-label-error {
	color: #f44336;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
	background-color: #424242;
}

.mat-stepper-vertical-line::before {
	border-left-color: rgb(255 255 255 / 12%);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
	border-top-color: rgb(255 255 255 / 12%);
}

.mat-horizontal-stepper-header {
	height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
	padding: 24px;
}

.mat-stepper-vertical-line::before {
	top: -16px;
	bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
	top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
	top: 36px;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
	font-size: 14px;
	font-weight: 400;
}

.mat-step-sub-label-error {
	font-weight: normal;
}

.mat-step-label-error {
	font-size: 16px;
}

.mat-step-label-selected {
	font-size: 16px;
	font-weight: 400;
}

.mat-sort-header-arrow {
	color: #c6c6c6;
}

.mat-toolbar {
	background: #212121;
	color: #fff;
}

.mat-toolbar.mat-primary {
	background: #7b1fa2;
	color: #fff;
}

.mat-toolbar.mat-accent {
	background: #69f0ae;
	color: rgb(0 0 0 / 87%);
}

.mat-toolbar.mat-warn {
	background: #f44336;
	color: #fff;
}

.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
	background-color: currentcolor;
}

.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
	color: inherit;
}

.mat-toolbar .mat-input-element {
	caret-color: currentcolor;
}

.mat-toolbar-multiple-rows {
	min-height: 64px;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
	height: 64px;
}
@media (max-width: 599px) {
	.mat-toolbar-multiple-rows {
		min-height: 56px;
	}

	.mat-toolbar-row,
	.mat-toolbar-single-row {
		height: 56px;
	}
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
	font: 500 20px/32px Roboto, sans-serif;
	letter-spacing: 0.0125em;
	margin: 0;
}

.mat-tree {
	background: #424242;
}

.mat-tree-node,
.mat-nested-tree-node {
	color: #fff;
}

.mat-tree-node {
	min-height: 48px;
}

.mat-tree {
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
	font-weight: 400;
	font-size: 14px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
	font: 400 24px/32px Roboto, sans-serif;
	letter-spacing: normal;
	margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
	font: 500 20px/32px Roboto, sans-serif;
	letter-spacing: 0.0125em;
	margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
	font: 400 16px/28px Roboto, sans-serif;
	letter-spacing: 0.009375em;
	margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
	font: 400 16px/24px Roboto, sans-serif;
	letter-spacing: 0.03125em;
	margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
	font: 400 calc(14px * 0.83) / 20px Roboto, sans-serif;
	margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
	font: 400 calc(14px * 0.67) / 20px Roboto, sans-serif;
	margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
	font: 500 14px/22px Roboto, sans-serif;
	letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
	font: 400 14px/20px Roboto, sans-serif;
	letter-spacing: 0.0178571429em;
}

.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
	margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
	font: 400 12px/20px Roboto, sans-serif;
	letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
	font: 300 96px/96px Roboto, sans-serif;
	letter-spacing: -0.015625em;
	margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
	font: 300 60px/60px Roboto, sans-serif;
	letter-spacing: -0.0083333333em;
	margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
	font: 400 48px/50px Roboto, sans-serif;
	letter-spacing: normal;
	margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
	font: 400 34px/40px Roboto, sans-serif;
	letter-spacing: 0.0073529412em;
	margin: 0 0 64px;
}
