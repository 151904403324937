@import "./variables";

h2[floatingText] {
	font-family: Nabla, sans-serif;
	font-size: 48px;
	text-align: center;
	position: relative;
}

h2[floatingText]::before {
	content: attr(data-text);
	opacity: 0.1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

h2[floatingText] span.letter {
	display: inline-block;
	position: relative;
	animation: float 5s ease-in-out infinite;
}

.float {
	animation: float 3s ease-in-out infinite;
}

@keyframes float {
	0% {
		transform: translateY(0) translateX(-5px);
	}

	25% {
		transform: translateY(-5px) translateX(5px);
	}

	50% {
		transform: translateY(0) translateX(-5px);
	}

	75% {
		transform: translateY(-5px) translateX(5px);
	}

	100% {
		transform: translateY(0) translateX(-5px);
	}
}

/* stylelint-disable keyframes-name-pattern */
@keyframes bounce {
	0%,
	50%,
	100% {
		transform: translateY(0);
	}

	25% {
		transform: translateY(-10px);
	}

	75% {
		transform: translateY(-5px);
	}
}
@keyframes bounce {
	0%,
	50%,
	100% {
		transform: translateY(0);
	}

	25% {
		transform: translateY(-10px);
	}

	75% {
		transform: translateY(-6px);
	}
}
@keyframes bounce {
	0%,
	50%,
	100% {
		transform: translateY(0);
	}

	25% {
		transform: translateY(-10px);
	}

	75% {
		transform: translateY(-6px);
	}
}

@keyframes swim {
	0% {
		margin-left: -235px;
	}

	90% {
		margin-left: 100%;
	}

	100% {
		margin-left: 100%;
	}
}

@keyframes swim {
	0% {
		margin-left: -235px;
	}

	70% {
		margin-left: 100%;
	}

	100% {
		margin-left: 100%;
	}
}

.animated-gradient {
	background: linear-gradient(230deg, #000, #170036, #020065, #290032, #2d2b2b);
	background-size: 1000% 1000%;
	animation: AnimationName 32s ease infinite;
}

@keyframes AnimationName {
	0% {
		background-position: 74% 0%;
	}

	50% {
		background-position: 27% 100%;
	}

	100% {
		background-position: 74% 0%;
	}
}
@keyframes AnimationName {
	0% {
		background-position: 74% 0%;
	}

	50% {
		background-position: 27% 100%;
	}

	100% {
		background-position: 74% 0%;
	}
}
@keyframes AnimationName {
	0% {
		background-position: 74% 0%;
	}

	50% {
		background-position: 27% 100%;
	}

	100% {
		background-position: 74% 0%;
	}
}

/* The typing effect */
@keyframes typing {
	from {
		width: 0;
	}

	to {
		width: 100%;
	}
}

/* The typewriter cursor effect */
@keyframes blink-caret {
	0%,
	100% {
		border-color: transparent;
	}

	50% {
		border-color: #0f0;
	}
}

$glow-colors: $c-sea-green, $synthwave-1, $synthwave-2, $synthwave-3,
	$synthwave-4, $synthwave-5, $synthwave-6;

@keyframes glow-border {
	@for $i from 1 through length($glow-colors) {
		$color: nth($glow-colors, $i);
		$next-color: nth($glow-colors, if($i == length($glow-colors), 1, $i + 1));
		$delay: calc(1 / length($glow-colors) * ($i - 1)) *
			100%; // delay based on index

		#{$delay} {
			border-color: $color;
		}
		#{$delay + 50%} {
			border-color: $next-color;
			box-shadow:
				0 0 10px $next-color,
				0 0 20px $next-color,
				0 0 30px $next-color;
		}
	}
}

.glow-border {
	animation-delay: 3s;
	border: 1px solid transparent; // initially transparent border
	animation: glow-border 20s ease-in-out 3s infinite alternate;
	transition: border-color 5s ease-in-out;

	&:hover {
		border-color: $c-sea-green !important;
		animation: none;
	}
}

$glow-colors-inner: $c-purple, $c-slate;

@keyframes glow-border-inner {
	@for $i from 1 through length($glow-colors-inner) {
		$color: nth($glow-colors-inner, $i);
		$next-color: nth(
			$glow-colors-inner,
			if($i == length($glow-colors-inner), 1, $i + 1)
		);
		$delay: calc(1 / length($glow-colors-inner) * ($i - 1)) *
			100%; // delay based on index

		#{$delay} {
			border-color: $color;
		}
		#{$delay + 50%} {
			border-color: $next-color;
			box-shadow:
				inset 0 0 10px $next-color,
				inset 0 0 5px $next-color,
				inset 0 0 15px $next-color;
		}
	}
}

.glow-border-inner {
	animation-delay: 3s;
	border: 1px solid transparent; // initially transparent border
	animation: glow-border-inner 3s ease-in-out 3s infinite alternate;
	transition: border-color 5s ease-in-out;

	&:hover {
		border-color: $c-sea-green !important;
		animation: none;
	}
}

@keyframes lasers {
	0% {
		transform: rotateX(25deg);
	}

	25% {
		transform: rotateX(25deg) scale(0.9);
	}

	60%,
	100% {
		transform: none;
	}
}

.lasers {
	animation-duration: 1400ms;
	animation-timing-function: ease;
	animation-iteration-count: 1;
	animation-direction: normal;
	animation-fill-mode: forwards;
	animation-play-state: running;
	animation-name: lasers;
	animation-delay: calc(var(--base-delay) + 400ms);
}
