@import "app/style/typeography";
@import "app/style/variables";
@import "app/style/tweakpane";
@import "app/style/animations";

html {
	font-size: 16px;
	@media screen and (max-width: $breakpoint-mobile) {
		font-size: 14px;
	}
}

body {
	background-color: #000;
	margin: 0;
	padding: 0;
	font-family: $font-family-euro, "Helvetica Neue", sans-serif;
	color: white;
	overflow-x: hidden;

	--webkit-tap-highlight-color: transparent;
}

a {
	color: black;

	&:hover {
		text-decoration: underline !important;
	}
}

*::-webkit-scrollbar {
	width: 8px;

	// background-color: rgb(0 255 0 / 100%);
}

*::-webkit-scrollbar-thumb {
	background-color: rgb(0 255 0 / 100%);
	width: 2px;
	@media screen and (max-width: $breakpoint-mobile) {
		width: 1px;
	}
}

.content.no-console {
	height: unset;
	padding-top: 0 !important;
	@media screen and (max-width: $breakpoint-desktop) and (orientation: landscape) {
		height: calc(100dvh);
	}

	.scene-loader {
		video {
			height: calc(100vh - 32px) !important;
		}
	}
}

// Standalone PWA

.standalone {
	.arcade-console {
		background-color: transparent !important;
	}

	.arcade-tile {
		margin: 0 !important;
		padding: 0 !important;
		height: 100% !important;
	}

	.arcade-tile-wrapper {
		margin: 0 !important;
		padding: 0 !important;
		height: 100% !important;
	}

	.content {
		// height: env(safe-area-inset-top);

		// width: env(safe-area-inset-left);
		// margin: env(safe-area-inset-top);
		// border: 1px solid $c-terminal-green; /* add top border */
		// z-index: 9999;
		border-top-left-radius: env(safe-area-inset-top);
		border-top-right-radius: env(safe-area-inset-top);

		// you can also use fallback values
		// padding: env(safe-area-inset-bottom, 20px);

		// .arcade-tile {
		// 	margin: 0 !important;
		// }

		// .tile-content {
		// 	padding: 0 !important;
		// }
	}
}

// Old Typography Helpers
.digital {
	font-family: $font-family-digital;
	color: $c-sea-green;
	font-size: 12px;
}

.error {
	color: red;
}

.arcade-button {
	text-transform: uppercase;
	font-family: "Press Start 2P", monospace !important;
	letter-spacing: 1px;
	padding: 10px 20px;
	margin: 0 10px;

	&:hover {
		color: black;
		text-decoration: underline;
	}

	a {
		font-family: "Press Start 2P", monospace !important;
		text-transform: uppercase;
		letter-spacing: 1px;
		text-decoration: none;
		color: inherit;

		&:hover {
			color: black;
			text-decoration: underline;
		}
	}

	&.danger {
		color: red;
	}
}

.arcade {
	text-transform: uppercase;
	font-family: "Press Start 2P", monospace !important;
	letter-spacing: 1px;

	/* stylelint-disable-next-line no-descending-specificity */
	a {
		font-family: "Press Start 2P", monospace !important;
		text-transform: uppercase;
		letter-spacing: 1px;
		text-decoration: none;
		color: inherit;

		&:hover {
			color: black;
			text-decoration: underline;
		}
	}
}

.login-wrapper {
	display: flex;
	flex-direction: column;

	h4 {
		margin-bottom: 20px;
	}

	.login-buttons-wrapper {
		display: flex;

		button {
			margin: 0 10px;
		}
	}
}

.user-wrapper {
	display: flex;
	flex-direction: column;
	border: 1px solid black;
	justify-content: center;
	padding: 30px;
	align-items: center;
	text-align: center;
	border-radius: 10px;
	margin: 15px auto;
	max-width: 550px;

	.btn-logout {
		width: 200px;
	}
}

.mint-wrapper {
	display: flex;
	flex-direction: column;
	border: 1px solid black;
	justify-content: center;
	padding: 30px;
	align-items: center;
	border-radius: 10px;
	margin: 15px auto;
	max-width: 550px;
}

.nft-wrapper {
	display: flex;
	flex-direction: column;

	// border: 1px solid black;
	justify-content: center;

	// padding: 30px;
	align-items: center;

	// border-radius: 10px;
	margin: 15px auto;
	max-width: 550px;

	.nft {
		border-radius: 10px;
		border: 1px solid black;
		padding: 30px;
	}
}

// .eth-address {
// }

.members-card {
	.members-address {
		font-family: monospace;
	}
}

@media only screen and (width <= 2in) {
	.user-wrapper {
		display: none;
	}
}

// Navigation
.nav-logo {
	margin-right: 8px !important;
}

.example-icon {
	padding: 0 14px;
}

.nav-spacer {
	flex: 1 1 auto;
}

.home-button {
	padding: 0 !important;
}

// Navbar Helpers
.nav-padding {
	padding-top: $nav-height;
}

.nav-margin {
	margin-top: $nav-height;
}

.page-content-3 {
	position: absolute;
	top: calc($nav-height - 16px);
	padding-bottom: 10px;
	width: 100vw;
	height: calc(100vh);

	// margin-bottom: $console-height;
}

.page-content-2 {
	// padding-top: $nav-height;
	margin-bottom: $console-height;
	padding-bottom: 10px;
}

.page-content {
	padding-bottom: 10px;
	width: 100vw;
	margin-bottom: $console-height;
	margin-top: $nav-height;

	// later- do this
	// position: absolute;
	// top: 0;
	// left: 0;

	.navbar {
		justify-content: center;
		font-palette: --2;
		text-transform: uppercase;
		font-family: $font-family-nabla;
		base-palette: 2;
		font-weight: bold;
		width: 100%;
		color: #fff;
		text-align: center;
		margin: 0;
		padding: 0;
		line-height: 1.23;
		margin-bottom: 10px;
		font-variation-settings:
			"EDPT" 120,
			"EHLT" 12;
		white-space: break-spaces;
		@media (max-width: $breakpoint-mobile) {
			font-size: 2em;
		}

		font-size: 3em;
		letter-spacing: 2px;

		// letter-spacing: 1px;

		.nav-left {
			width: 30%;
			display: flex;
			height: 100%;
			align-items: center;
		}

		.nav-center {
			width: 40%;
			flex: 1 0 0;
			align-items: center;
			justify-content: center;
			height: 100%;
			display: flex;
		}

		.nav-right {
			width: 30%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: 100%;
		}
	}

	.page-inner-content {
		background: #212121;
		color: #fff;
		width: 100%;
	}
}

.flex-spacer {
	flex: 1 1 auto;
}

.card-actions {
	display: flex !important;
}

.no-objects {
	img {
		max-width: 100px;
		margin-bottom: 10px;
		margin-top: 10px;
		display: block;
	}
}

.hash {
	word-break: break-all;
	max-width: 100%;

	// font-family: monospace;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.airtable-embed {
	max-width: 540px;
	margin: auto;
}

.lil-gui {
	top: 165px !important;
	right: initial !important;
}

.fade {
	display: none;

	// animation-name: fadeOut;
	// animation-duration: 3s;
}

canvas {
	// cursor: crosshair;
	// cursor: none !important;
	position: relative;
}

#content {
	// display: block;
	// position: relative;

	// min-height: calc(100% - 32px);
}

* {
	// cursor: none !important;
}

.nocursor {
	cursor: none;
}

.crosshair {
	// cursor: crosshair;
	cursor: none;
}

.pointer,
.pointer-system {
	cursor: pointer !important;

	&:hover {
		cursor: pointer !important;
	}
}

.link {
	cursor: pointer !important;
}

.pre-break {
	white-space: break-spaces;
	max-width: 100%;
	word-break: break-all;
	color: $c-terminal-green;
}

.arcade-actions .members-menu.mat-mdc-menu-panel {
	max-width: 280px;

	// margin-left: 64px !important;
	width: 100vw;

	// margin-left: 10px !important;
	// margin-top: 10px !important;
	padding: 0;
	margin: 3px;
	background: #0080ff11;
	background: rgb(0 0 0 / 50%);
	display: flex;
	flex-grow: 2;
	height: 100%;
	max-height: calc(100vh - $nav-height);

	@media screen and (max-width: $breakpoint-mobile) {
		max-width: 100%;
	}

	> div {
		width: 100%;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;

		// height: calc(100vh - $nav-height);
		justify-content: flex-start;

		button {
			text-align: center;
			padding: 0 4px;

			// font-size: 10px;
			word-wrap: break-word;
		}
	}
}

[style^='clip-path: url("#hex-hw-shapeclip-clipconfig");'] {
	background-color: black;
}

[style^='clip-path: url("#hex-hw-shapeclip-clipconfig");']::before {
	background-color: black;
	color: white;
	content: "NFT USER lmao";
	position: absolute;
	text-align: center;
}

.twitter {
	clip-path: url("#hex-hw-shapeclip-clipconfig");
}

.monospace {
	font-family: monospace;
}

.mat-mdc-button {
	font-family: $font-family-monospace;
}

.black {
	color: black;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
	font-family: monospace;
}

// Overrride
.cdk-overlay-container {
	z-index: 2003;
}

.terminal {
	color: rgb(0 255 0);
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.gallery-container .mat-tab-label {
	height: 60px;
}

// Grid Stuff
.arcade-grid-tile {
	// margin: 16px;
}

arcade-tile {
	width: 100%;
	height: 100%;
	max-width: 100%;
	max-height: 100%;
	box-sizing: border-box;
}

.console-wrapper {
	width: 100%;
	max-width: 100%;
	position: relative;
}

mat-grid-list {
	// width: 100%;
	// margin: 10px auto;
	// max-width: $breakpoint-tablet;

	@media (max-width: $breakpoint-tablet) {
		max-width: 100%;
	}
	@media (max-width: $breakpoint-mobile) {
		max-width: 100%;
	}
}

.mat-mdc-dialog-title {
	text-transform: uppercase;
}

/* stylelint-disable-next-line selector-id-pattern */
.arcade-button-vr {
	z-index: 1201 !important;
	left: calc(50% - 90px) !important;
	top: calc(50% - 30px);
	color: white !important;
}

.arcade-buttons-wrapper {
	flex-wrap: wrap;
	display: flex;
	justify-content: center;
}

// Arcade Display
.arcade-display-full-width {
	width: 100%;
}

// Music Player
.arcade-music-player {
	.arcade-music-buttons {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: stretch;
		margin-top: 5px;

		.arcade-music-button {
			background-color: #00e785;
			box-shadow:
				0 3.85px 3.85px rgb(0 0 0 / 25%),
				inset 0 1.92px 1.92px rgb(0 0 0 / 25%);
			border-radius: 2.88px;
			flex-grow: 2;
			margin-right: 5px;
			width: 25%;
			min-width: initial !important;
			line-height: 1;
			padding: 0;
			height: 100%;

			/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
			.mat-button-ripple,
			.mat-button-wrapper,
			.mat-button-focus-overlay {
				min-width: initial !important;
				line-height: 1;
				padding: 0;
			}

			// height: 60px;
			mat-icon {
				// display: none;
				margin: 0;
				height: auto;
				font-size: 38px;
				width: 100%;

				// filter: blur(1.1px);

				&:hover {
					filter: initial;
				}
				@media screen and (max-width: $breakpoint-tablet) {
					font-size: 32px;
				}
				@media screen and (max-width: $breakpoint-mobile) {
					font-size: 28px;
				}
				@media screen and (max-width: $breakpoint-watch) {
					font-size: 24px;
				}
			}

			&.button-green {
				background-color: $c-sea-green;
			}

			&.button-orange {
				background-color: $c-orange;
			}

			&.button-red {
				background-color: $c-red;
			}

			&.button-blue {
				background-color: $c-sea-blue;
			}

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				// background-color: $c-sea-green;
			}

			&:active {
				// background-color: $c-dark-grey;
			}

			&:focus {
				// background-color: $c-dark-grey;
			}
		}
	}
}

// .cols-3 {
// 	.arcade-music-button {
// 		min-width: initial;
// 		padding: 0;

// 		/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
// 		.mat-button-wrapper {
// 			min-width: initial;
// 		}

// 		mat-icon {
// 			font-size: 2.5em !important;
// 		}
// 	}
// }

// Layout Helpers
.mr-5 {
	margin-right: 5px;
}

.mat-mdc-list-option-checkbox-before {
	margin: 0 !important;
}

// Swipe Down
#refresh-icon {
	position: absolute;
	top: -60px;
	right: 20px;
	display: none;
	animation: spin 2s infinite linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

// ar test
.ar-button {
	z-index: 2001;
	position: absolute;
	top: 49%;
	left: 49%;
}

// vertical player
.player-stacked > .mat-grid-tile-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	arcade-tile {
		height: calc($console-height / 2);
	}
}
